import React from "react";
import {
  Box,
  Typography,
  Avatar,
  alpha,
  styled,
  Card,
  Tooltip,
  Button,
} from "@mui/material";
import {
  Group as GroupIcon,
  Person as PersonIcon,
  CheckCircle as AcceptIcon,
  Close as DeclineIcon,
} from "@mui/icons-material";
import { formatDistanceToNow } from "date-fns";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: "blur(8px)",
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const ActionButton = styled(Button)(({ theme, variant }) => ({
  minWidth: 100,
  backgroundColor:
    variant === "accept"
      ? alpha(theme.palette.success.main, 0.1)
      : alpha(theme.palette.error.main, 0.1),
  color:
    variant === "accept"
      ? theme.palette.success.main
      : theme.palette.error.main,
  "&:hover": {
    backgroundColor:
      variant === "accept"
        ? alpha(theme.palette.success.main, 0.2)
        : alpha(theme.palette.error.main, 0.2),
  },
}));

const MessageBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const StatusSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.background.default, 0.3),
  borderRadius: theme.shape.borderRadius,
}));

const InviteCard = ({
  invite,
  emailStatus,
  showActions = false,
  onAccept,
  onDecline,
  onResend,
  isResending = false,
}) => {
  const isGroup = invite.type === "group";

  return (
    <StyledCard>
      <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
        <Avatar
          src={invite.from.photoURL || "/api/placeholder/40/40"}
          sx={{ width: 40, height: 40 }}
        />
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}>
            <Typography variant="subtitle1">
              {invite.from.displayName || invite.from.email}
            </Typography>
            <Tooltip title={isGroup ? "Group Invite" : "Personal Invite"}>
              <Box sx={{ color: "text.secondary" }}>
                {isGroup ? <GroupIcon /> : <PersonIcon />}
              </Box>
            </Tooltip>
          </Box>

          {isGroup && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Invited you to join {invite.metadata.groupName}
            </Typography>
          )}

          {invite.message && (
            <MessageBox>
              <Typography variant="body2">"{invite.message}"</Typography>
            </MessageBox>
          )}

          <StatusSection>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="caption" color="text.secondary">
                {formatDistanceToNow(invite.createdAt.toDate(), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
            {emailStatus}
          </StatusSection>

          {showActions && (
            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
              <ActionButton
                variant="accept"
                onClick={onAccept}
                startIcon={<AcceptIcon />}
              >
                Accept
              </ActionButton>
              <ActionButton
                variant="decline"
                onClick={onDecline}
                startIcon={<DeclineIcon />}
              >
                Decline
              </ActionButton>
            </Box>
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};

export default InviteCard;
