import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../backend-services/firebase";
import BillForm from "./BillForm";
import { BackButton, PageContainer } from "./AddBill";

const EditBill = () => {
  const { billId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [billData, setBillData] = useState(null);

  useEffect(() => {
    const fetchBillData = async () => {
      try {
        setLoading(true);
        setError(null);

        const billRef = doc(db, "bills", billId);
        const billDoc = await getDoc(billRef);

        if (!billDoc.exists()) {
          throw new Error("Bill not found");
        }

        const bill = billDoc.data();

        if (bill.status === "completed") {
          throw new Error("Cannot edit a completed bill");
        }

        if (bill.participants.some(p => p.settled && p.userId !== bill.paidBy)) {
          throw new Error("Cannot edit a bill with settled participants");
        }

        setBillData({
          id: billDoc.id,
          ...bill
        });
      } catch (err) {
        console.error("Error fetching bill:", err);
        setError(err.message || "Failed to load bill details");
      } finally {
        setLoading(false);
      }
    };

    fetchBillData();
  }, [billId, user.uid]);

  const handleBack = () => {
    if (billData?.groupId) {
      navigate(`/groups/${billData.groupId}`);
    } else {
      navigate("/bills");
    }
  };

  if (loading) {
    return (
      <PageContainer maxWidth="md">
        <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer maxWidth="md">
        <BackButton startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back
        </BackButton>
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </PageContainer>
    );
  }

  return (
    <PageContainer maxWidth="md">
      <BackButton startIcon={<ArrowBackIcon />} onClick={handleBack}>
        Back
      </BackButton>
      
      <Typography variant="h4" color="primary" sx={{ mb: 4, fontWeight: "bold" }}>
        Edit Bill
      </Typography>

      <BillForm
        initialData={billData}
        mode="edit"
        onSuccess={() => {
          if (billData?.groupId) {
            navigate(`/groups/${billData.groupId}`);
          } else {
            navigate("/bills");
          }
        }}
      />
    </PageContainer>
  );
};

export default EditBill;