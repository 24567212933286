import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  serverTimestamp,
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
  Timestamp,
  increment,
} from "firebase/firestore";
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  onValue,
  serverTimestamp as rtdbServerTimestamp,
} from "firebase/database";
import { calculateGroupSettlements as calculateDetailedGroupSettlements } from "./groupSettlementsLogic";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const GROUP_LIMITS = {
  MAX_MEMBERS: 20,
  MIN_MEMBERS: 1,
  NAME_MIN_LENGTH: 3,
  NAME_MAX_LENGTH: 50,
};
const INVITE_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  EXPIRED: "expired",
};
const INVITE_TYPE = {
  GROUP: "group",
  PERSONAL: "personal",
};
const INVITE_EXPIRY_DAYS = 7;

const firebaseConfig = {
  apiKey: "AIzaSyCNlriwUZ7HxeGJqgjYZNDGxI_NNy31enA",
  authDomain: "wise-split-be.firebaseapp.com",
  projectId: "wise-split-be",
  storageBucket: "wise-split-be.firebasestorage.app",
  messagingSenderId: "1097969179249",
  appId: "1:1097969179249:web:dadeb50dfdeffbd431d9d0",
  measurementId: "G-Y7GKF597N0",
  databaseURL: "https://wise-split-be-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
await setPersistence(auth, browserLocalPersistence);
export {
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
};

export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

export const functions = getFunctions(app, "us-central1");
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
const corsOptions = {
  origin: true,
  methods: ["POST", "GET", "PUT", "DELETE", "OPTIONS"],
  allowedHeaders: ["Content-Type", "Authorization"],
  credentials: true,
};

export const authPersistence = {
  SESSION: browserSessionPersistence,
  LOCAL: browserLocalPersistence,
};

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log("Auth state changed: User is signed in");
  } else {
    console.log("Auth state changed: User is signed out");
  }
});

// Invite Management Functions
export const createInvite = async (inviteData) => {
  try {
    const userDoc = await getDoc(doc(db, "users", inviteData.fromUserId));
    if (!userDoc.exists()) {
      throw new Error("User not found");
    }

    const batch = writeBatch(db);
    const inviteRef = doc(collection(db, "invites"));

    const invite = {
      id: inviteRef.id,
      type: inviteData.type,
      status: "pending",
      fromUserId: inviteData.fromUserId,
      from: {
        userId: inviteData.fromUserId,
        displayName: userDoc.data().displayName || "",
        email: userDoc.data().email || "",
        photoURL: userDoc.data().photoURL || "",
      },
      to: {
        email: inviteData.toEmail.toLowerCase(),
        userId: null,
        displayName: "",
        photoURL: "",
      },
      message: inviteData.message || "",
      groupId: inviteData.groupId || null,
      createdAt: serverTimestamp(),
      metadata: {
        groupName: inviteData.type === "group" ? inviteData.groupName : null,
      },
    };

    batch.set(inviteRef, invite);
    await batch.commit();

    return {
      id: inviteRef.id,
      ...invite,
    };
  } catch (error) {
    console.error("Error creating invite:", error);
    throw new Error(`Failed to create invite: ${error.message}`);
  }
};

export const getInviteById = async (inviteId) => {
  try {
    const inviteDoc = await getDoc(doc(db, "invites", inviteId));
    if (!inviteDoc.exists()) {
      throw new Error("Invite not found");
    }
    return { id: inviteDoc.id, ...inviteDoc.data() };
  } catch (error) {
    console.error("Error getting invite:", error);
    throw error;
  }
};

export const getUserInvites = async (userId, status = "pending") => {
  try {
    const invitesQuery = query(
      collection(db, "invites"),
      where("to.userId", "==", userId),
      where("status", "==", status),
      orderBy("createdAt", "desc")
    );

    const snapshot = await getDocs(invitesQuery);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error getting user invites:", error);
    throw error;
  }
};

export const updateInviteStatus = async (inviteId, status, userId) => {
  try {
    const inviteRef = doc(db, "invites", inviteId);
    const inviteDoc = await getDoc(inviteRef);

    if (!inviteDoc.exists()) {
      throw new Error("Invite not found");
    }

    const inviteData = inviteDoc.data();

    // Verify the user is the invite recipient
    if (inviteData.to.userId !== userId) {
      throw new Error("Unauthorized to update this invite");
    }

    // Verify status is valid
    if (!Object.values(INVITE_STATUS).includes(status)) {
      throw new Error("Invalid status");
    }

    await updateDoc(inviteRef, {
      status,
      updatedAt: serverTimestamp(),
    });

    // If accepted and it's a group invite, add user to group
    if (
      status === INVITE_STATUS.ACCEPTED &&
      inviteData.type === INVITE_TYPE.GROUP &&
      inviteData.groupId
    ) {
      await addMemberToGroup(
        inviteData.groupId,
        {
          userId: userId,
          displayName: inviteData.to.displayName,
          email: inviteData.to.email,
          photoURL: inviteData.to.photoURL,
        },
        inviteData.from.userId
      );
    }

    return { success: true };
  } catch (error) {
    console.error("Error updating invite status:", error);
    throw error;
  }
};

export const updateInviteEmailStatus = async (inviteId, status) => {
  if (!inviteId) {
    console.error("No invite ID provided to updateInviteEmailStatus");
    throw new Error("Invite ID is required");
  }

  try {
    const inviteRef = doc(db, "invites", inviteId);

    const inviteSnap = await getDoc(inviteRef);
    if (!inviteSnap.exists()) {
      throw new Error("Invite not found");
    }

    await updateDoc(inviteRef, {
      emailSent: status.emailSent ?? false,
      emailId: status.emailId ?? null,
      emailSentAt: status.emailSent ? serverTimestamp() : null,
      ...(status.error && { emailError: status.error }),
    });

    return { success: true };
  } catch (error) {
    console.error("Error updating invite email status:", error);
    throw error;
  }
};

// User Management Functions
// Create or update user document in Firestore
export const createOrUpdateUser = async (user) => {
  if (!user) return null;

  const userRef = doc(db, "users", user.uid);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    const userData = {
      userId: user.uid,
      displayName: user.displayName || "",
      email: user.email || "",
      photoURL: user.photoURL || "",
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      isOnboarded: user.inviteContext ? true : false,
      isNewUser: true,
      settings: {
        defaultCurrency: "USD",
        notifications: true,
      },
      recentInteractions: [],
      ...(user.inviteContext && {
        inviteContext: user.inviteContext,
      }),
    };

    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error creating user document:", error);
      throw error;
    }
  } else {
    const existingData = userSnap.data();
    const userData = {
      ...existingData,
      userId: user.uid,
      displayName: user.displayName || existingData.displayName || "",
      email: user.email || existingData.email || "",
      photoURL: user.photoURL || existingData.photoURL || "",
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      settings: {
        ...(existingData.settings || {}),
        defaultCurrency: existingData.settings?.defaultCurrency || "USD",
        notifications: existingData.settings?.notifications ?? true,
      },
      recentInteractions: existingData.recentInteractions || [],
      ...(user.inviteContext && {
        inviteContext: user.inviteContext,
      }),
    };

    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error updating user document:", error);
      throw error;
    }
  }
};

// User Search Functions
export const searchUsers = async (searchTerm, excludeUserIds = []) => {
  if (!searchTerm || searchTerm.length < 2) return [];

  const usersRef = collection(db, "users");

  try {
    const nameQuery = query(
      usersRef,
      where("displayName", ">=", searchTerm.toLowerCase()),
      where("displayName", "<=", searchTerm.toLowerCase() + "\uf8ff"),
      orderBy("displayName"),
      limit(5)
    );

    const emailQuery = query(
      usersRef,
      where("email", ">=", searchTerm.toLowerCase()),
      where("email", "<=", searchTerm.toLowerCase() + "\uf8ff"),
      orderBy("email"),
      limit(5)
    );

    const [nameSnapshot, emailSnapshot] = await Promise.all([
      getDocs(nameQuery),
      getDocs(emailQuery),
    ]);

    const results = new Map();

    nameSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    emailSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    return Array.from(results.values());
  } catch (error) {
    console.error("Error searching users:", error);
    throw new Error("Unable to search users. Please try again later.");
  }
};

// Initialize user presence system
export const initializePresence = async (userId) => {
  if (!userId) return;

  const userStatusRef = ref(realtimeDb, `/status/${userId}`);
  const userStatusFirestoreRef = doc(db, "users", userId);

  const connectionRef = ref(realtimeDb, ".info/connected");

  onValue(connectionRef, async (snapshot) => {
    if (snapshot.val() === false) {
      await updateDoc(userStatusFirestoreRef, {
        isOnline: false,
        lastSeen: serverTimestamp(),
      });
      return;
    }

    try {
      await onDisconnect(userStatusRef).set({
        isOnline: false,
        lastSeen: rtdbServerTimestamp(),
      });

      await Promise.all([
        set(userStatusRef, {
          isOnline: true,
          lastSeen: rtdbServerTimestamp(),
        }),
        updateDoc(userStatusFirestoreRef, {
          isOnline: true,
          lastSeen: serverTimestamp(),
        }),
      ]);
    } catch (error) {
      console.error("Error updating presence:", error);
    }
  });
};

// Get user's online status
export const getUserOnlineStatus = async (userId) => {
  if (!userId) return { isOnline: false, lastSeen: null };

  try {
    const userDoc = await getDoc(doc(db, "users", userId));
    if (!userDoc.exists()) return { isOnline: false, lastSeen: null };

    const userData = userDoc.data();
    return {
      isOnline: userData.isOnline || false,
      lastSeen: userData.lastSeen?.toDate() || null,
    };
  } catch (error) {
    console.error("Error getting user online status:", error);
    return { isOnline: false, lastSeen: null };
  }
};

// Subscribe to user's online status changes
export const subscribeToUserPresence = (userId, callback) => {
  if (!userId) return () => {};

  const userRef = doc(db, "users", userId);
  return onSnapshot(
    userRef,
    (doc) => {
      if (!doc.exists()) {
        callback({ isOnline: false, lastSeen: null });
        return;
      }

      const userData = doc.data();
      callback({
        isOnline: userData.isOnline || false,
        lastSeen: userData.lastSeen?.toDate() || null,
      });
    },
    (error) => {
      console.error("Error subscribing to presence:", error);
      callback({ isOnline: false, lastSeen: null });
    }
  );
};

// Recent Interactions Functions
export const updateRecentInteraction = async (
  currentUserId,
  interactedUserId
) => {
  if (!currentUserId || !interactedUserId || currentUserId === interactedUserId)
    return;

  try {
    const userRef = doc(db, "users", currentUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      let recentInteractions = userData.recentInteractions || [];

      recentInteractions = recentInteractions.filter(
        (id) => id !== interactedUserId
      );

      recentInteractions.unshift(interactedUserId);

      recentInteractions = recentInteractions.slice(0, 10);

      await updateDoc(userRef, {
        recentInteractions,
        lastActive: serverTimestamp(),
      });
    }
  } catch (error) {
    console.error("Error updating recent interactions:", error);
  }
};

export const getRecentlyInteractedUsers = async (userId) => {
  if (!userId) return [];

  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) return [];

    const userData = userSnap.data();
    const recentInteractions = userData.recentInteractions || [];

    const recentUsers = await Promise.all(
      recentInteractions.slice(0, 5).map(async (interactedUserId) => {
        const interactedUserRef = doc(db, "users", interactedUserId);
        const interactedUserSnap = await getDoc(interactedUserRef);

        if (interactedUserSnap.exists()) {
          return interactedUserSnap.data();
        }
        return null;
      })
    );

    return recentUsers.filter(Boolean);
  } catch (error) {
    console.error("Error getting recent interactions:", error);
    return [];
  }
};

// Group Management Functions
export const createGroup = async (data) => {
  try {
    const { name, createdBy, members = [] } = data;

    console.log("Creating group with data:", {
      name,
      createdBy,
      membersCount: members.length,
      members: members,
    });

    // Verify auth state
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User must be authenticated to create a group");
    }

    // Get creator's user data
    const creatorDoc = await getDoc(doc(db, "users", createdBy));
    if (!creatorDoc.exists()) {
      throw new Error("Creator user not found");
    }
    const creatorData = creatorDoc.data();

    const currentTime = Timestamp.now();

    // Initialize group data with creator as first member
    const groupData = {
      id: doc(collection(db, "groups")).id,
      name: name.trim(),
      createdBy,
      createdAt: currentTime,
      updatedAt: currentTime,
      members: [
        {
          userId: createdBy,
          role: "admin",
          addedAt: currentTime,
          displayName: creatorData.displayName || "",
          email: creatorData.email || "",
          photoURL: creatorData.photoURL || "",
          status: "active",
        },
      ],
      memberUserIds: [createdBy],
      status: "active",
    };

    // Add other members, excluding the creator if they're somehow in the members array
    const otherMembers = members.filter(
      (member) => member.userId !== createdBy
    );

    // Add filtered members to the group data
    otherMembers.forEach((member) => {
      groupData.members.push({
        userId: member.userId,
        role: "member",
        addedAt: currentTime,
        displayName: member.displayName || "",
        email: member.email || "",
        photoURL: member.photoURL || "",
        status: "active",
      });
      groupData.memberUserIds.push(member.userId);
    });

    // Check for duplicates in memberUserIds
    const uniqueMemberIds = [...new Set(groupData.memberUserIds)];
    if (uniqueMemberIds.length !== groupData.memberUserIds.length) {
      throw new Error("Duplicate members detected");
    }

    console.log("Final group data:", groupData);

    // Create the group document
    await setDoc(doc(db, "groups", groupData.id), groupData);

    await logGroupCreation(groupData.id, {
      group: groupData,
      creator: {
        userId: createdBy,
        displayName: creatorData.displayName || "",
        photoURL: creatorData.photoURL || "",
        email: creatorData.email || "",
      },
    });

    return groupData.id;
  } catch (error) {
    console.error("Detailed error in createGroup:", error);
    throw error;
  }
};

export const getGroupById = async (groupId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
      throw new Error("Group not found");
    }

    return {
      id: groupSnap.id,
      ...groupSnap.data(),
    };
  } catch (error) {
    console.error("Error getting group:", error);
    throw error;
  }
};

export const getUserGroups = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }

    const groupsQuery = query(
      collection(db, "groups"),
      where("memberIds", "array-contains", userId),
      orderBy("createdAt", "desc")
    );

    const groupsSnap = await getDocs(groupsQuery);

    return groupsSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error getting user groups:", error);
    throw error;
  }
};

export const addMemberToGroup = async (groupId, newMember, addedBy) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
      throw new Error("Group not found");
    }

    const groupData = groupSnap.data();

    if (groupData.memberUserIds?.includes(newMember.userId)) {
      throw new Error("User is already a member of this group");
    }

    const batch = writeBatch(db);

    const memberData = {
      userId: newMember.userId,
      addedBy: addedBy,
      addedAt: serverTimestamp(),
      displayName: newMember.displayName || "",
      email: newMember.email || "",
      photoURL: newMember.photoURL || "",
      role: "member",
      status: "active",
    };

    batch.update(groupRef, {
      members: arrayUnion(memberData),
      memberUserIds: arrayUnion(newMember.userId),
      updatedAt: serverTimestamp(),
      lastModifiedBy: addedBy,
    });

    const userGroupRef = doc(db, "users", newMember.userId, "groups", groupId);
    batch.set(userGroupRef, {
      groupId: groupId,
      joinedAt: serverTimestamp(),
      addedBy: addedBy,
      role: "member",
      status: "active",
    });

    await batch.commit();

    return {
      success: true,
      memberId: newMember.userId,
    };
  } catch (error) {
    console.error("Error adding member to group:", error);
    if (error.code === "permission-denied") {
      throw new Error("You don't have permission to add members to this group");
    }
    throw error;
  }
};

export const removeMemberFromGroup = async (groupId, memberId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
      throw new Error("Group not found");
    }

    const groupData = groupSnap.data();
    const memberToRemove = groupData.members.find((m) => m.userId === memberId);

    if (!memberToRemove) {
      throw new Error("Member not found in group");
    }

    await updateDoc(groupRef, {
      members: arrayRemove(memberToRemove),
      memberIds: arrayRemove(memberId),
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error removing member from group:", error);
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    await deleteDoc(groupRef);
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};

export const subscribeToGroup = (groupId, callback) => {
  const groupRef = doc(db, "groups", groupId);

  return onSnapshot(
    groupRef,
    (doc) => {
      if (doc.exists()) {
        callback({
          id: doc.id,
          ...doc.data(),
        });
      } else {
        callback(null);
      }
    },
    (error) => {
      console.error("Error subscribing to group:", error);
      callback(null);
    }
  );
};

export const subscribeToUserGroups = (userId, callback) => {
  if (!userId) return () => {};

  const groupsQuery = query(
    collection(db, "groups"),
    where("memberUserIds", "array-contains", userId),
    where("status", "!=", "deleted")
  );

  return onSnapshot(
    groupsQuery,
    (snapshot) => {
      const groups = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          const dateA = a.createdAt?.toMillis() || 0;
          const dateB = b.createdAt?.toMillis() || 0;
          return dateB - dateA;
        });
      callback(groups);
    },
    (error) => {
      console.error("Error fetching groups:", error);
      callback([]);
    }
  );
};

// Bills Management Functions
export const createBill = async ({
  description,
  amount,
  paidBy,
  splitType,
  participants,
  createdBy,
  currency = "USD",
  category = "general",
  date,
  items = [],
  splitDetails = {},
  groupId = null,
}) => {
  try {
    if (!description?.trim() || !amount || !paidBy || !participants?.length) {
      throw new Error("Missing required fields for bill creation");
    }

    const batch = writeBatch(db);

    const updatedParticipants = participants.map((participant) => {
      const splitValue = splitDetails[participant.userId] || {};
      return {
        userId: String(participant.userId),
        displayName: String(participant.displayName || ""),
        email: String(participant.email || ""),
        photoURL: String(participant.photoURL || ""),
        share: {
          original: Number(participant?.share?.original),
          settlement:
            participant.userId === paidBy
              ? Number(participant?.share?.original) - Number(amount)
              : Number(participant?.share?.original),
        },
        splitValue: {
          percentage:
            splitType === "percentage"
              ? Number(splitValue.percentage || 0)
              : null,
          shares:
            splitType === "shares" ? Number(splitValue.shares || 0) : null,
        },
        shareType: String(splitType),
        settled: participant.settled || false,
        settledAt: participant.settledAt || null,
      };
    });

    const formattedItems = items.map((item) => ({
      name: String(item.name),
      quantity: Number(item.quantity),
      totalAmount: Number(item.totalAmount),
      participants: item.participants.map(String),
      perPersonShare: Number(item.perPersonShare),
      pricePerItem: Number(item.pricePerItem),
    }));

    const billRef = doc(collection(db, "bills"));

    const payerParticipant = updatedParticipants.find(
      (p) => p.userId === paidBy
    );

    const billData = {
      description: String(description),
      amount: Number(amount),
      paidBy: String(paidBy),
      splitType: String(splitType),
      participants: updatedParticipants,
      participantUserIds: participants.map((p) => String(p.userId)),
      createdBy: String(createdBy),
      currency: String(currency),
      category: String(category),
      items: splitType === "items" ? formattedItems : [],
      splitDetails:
        splitType === "percentage" || splitType === "shares"
          ? splitDetails
          : null,
      date: serverTimestamp(),
      status: "pending",
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      groupId: groupId,
      billType: groupId ? "group" : "personal",
    };

    batch.set(billRef, billData);

    if (payerParticipant) {
      const transactionRef = doc(collection(db, "transactions"));
      const transactionData = {
        billId: billRef.id,
        userId: String(paidBy),
        amount: {
          original: Number(payerParticipant.share.original),
          settlement: Number(payerParticipant.share.settlement),
        },
        createdAt: serverTimestamp(),
        settledAt: serverTimestamp(),
        settledBy: String(paidBy),
        billDescription: String(description),
        type: "payment",
        status: "completed",
        groupId: groupId,
      };

      batch.set(transactionRef, transactionData);
    }

    await batch.commit();
    return billRef.id;
  } catch (error) {
    console.error("Error creating bill:", error);
    throw error;
  }
};

export const getBillsByGroup = async (groupId) => {
  try {
    const q = query(
      collection(db, "bills"),
      where("groupId", "==", groupId),
      where("status", "!=", "deleted"),
      orderBy("date", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching group bills:", error);
    throw error;
  }
};

export const getUserBills = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }

    console.log("Fetching bills for userId:", userId);

    // Update the query to include all bill statuses
    const q = query(
      collection(db, "bills"),
      where("participantUserIds", "array-contains", userId),
      where("status", "!=", "deleted")
    );

    const querySnapshot = await getDocs(q);
    console.log("Query snapshot size:", querySnapshot.size); // Debug log

    const bills = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      console.log("Processing bill:", doc.id, data); // Debug log
      return {
        id: doc.id,
        ...data,
      };
    });

    console.log("Processed bills:", bills); // Debug log
    return bills;
  } catch (error) {
    console.error("Error in getUserBills:", error);
    throw error;
  }
};

export const getUserNonGroupBills = async (userId) => {
  try {
    const q = query(
      collection(db, "bills"),
      where("groupId", "==", null),
      where("participants", "array-contains", { userId }),
      where("status", "==", "active"),
      orderBy("date", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching user's non-group bills:", error);
    throw error;
  }
};

export const settleBillForUser = async (billId, userId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const updatedParticipants = billData.participants.map((participant) => {
      if (participant.userId === userId) {
        return {
          ...participant,
          settled: true,
          settledAt: serverTimestamp(),
        };
      }
      return participant;
    });

    // Check if all participants have settled
    const allSettled = updatedParticipants.every(
      (participant) => participant.settled
    );

    await updateDoc(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "settled" : "active",
    });
  } catch (error) {
    console.error("Error settling bill for user:", error);
    throw error;
  }
};

export const deleteBill = async (billId) => {
  try {
    const batch = writeBatch(db);

    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId)
    );

    const transactionsSnapshot = await getDocs(transactionsQuery);
    transactionsSnapshot.docs.forEach((transactionDoc) => {
      batch.delete(transactionDoc.ref);
    });

    const billRef = doc(db, "bills", billId);
    batch.delete(billRef);

    await batch.commit();

    return { success: true };
  } catch (error) {
    console.error("Error deleting bill:", error);
    throw error;
  }
};

export const undeleteBill = async (billId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    // Restore bill to pending status
    batch.update(billRef, {
      status: "pending",
      deletedAt: null,
    });

    // Also update any related transactions if needed
    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId)
    );
    const transactionsSnapshot = await getDocs(transactionsQuery);

    transactionsSnapshot.docs.forEach((transactionDoc) => {
      batch.update(doc(db, "transactions", transactionDoc.id), {
        status: "pending",
      });
    });

    await batch.commit();
    return { success: true };
  } catch (error) {
    console.error("Error undeleting bill:", error);
    throw error;
  }
};

export const updateBill = async (billId, updateData) => {
  try {
    const billRef = doc(db, "bills", billId);

    const sanitizedData = {
      ...updateData,
      amount: Number(updateData.amount),
      participants: updateData.participants.map((p) => ({
        ...p,
        share: {
          original: Number(p.share.original),
          settlement: Number(p.share.settlement),
        },
      })),
    };

    await updateDoc(billRef, sanitizedData);
    return true;
  } catch (error) {
    console.error("Error updating bill:", error);
    throw error;
  }
};

export const storeBillSettlements = async (billId, participants) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    batch.update(billRef, {
      settlementStatus: "pending",
      settlementUpdatedAt: serverTimestamp(),
      participants: participants.map((participant) => ({
        userId: participant.userId,
        share: participant.share,
        shareType: participant.shareType,
        settled: false,
        settledAt: null,
        displayName: participant.displayName || "",
        email: participant.email || "",
        photoURL: participant.photoURL || "",
      })),
    });

    if (billData.groupId) {
      const groupRef = doc(db, "groups", billData.groupId);
      const totalUnsettled = participants.reduce((sum, p) => sum + p.share, 0);

      batch.update(groupRef, {
        totalUnsettledAmount: increment(totalUnsettled),
        updatedAt: serverTimestamp(),
      });
    }

    await batch.commit();
    return true;
  } catch (error) {
    console.error("Error storing bill settlements:", error);
    throw error;
  }
};

export const addSettlementHistory = async (groupId, settlement) => {
  try {
    const historyRef = collection(db, "groups", groupId, "settlementHistory");
    await addDoc(historyRef, {
      ...settlement,
      createdAt: serverTimestamp(),
      status: "completed",
    });
  } catch (error) {
    console.error("Error adding settlement history:", error);
    throw error;
  }
};

export const updateBillStatus = async (billId, status) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    await updateDoc(billRef, {
      status,
      updatedAt: serverTimestamp(),
    });

    const billData = billDoc.data();
    if (billData.groupId && status === "settled") {
      await calculateGroupSettlements(billData.groupId);
    }
  } catch (error) {
    console.error("Error updating bill status:", error);
    throw error;
  }
};

export const markSettlementComplete = async (billId, participantId, amount) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    // Update participant's settlement status
    const updatedParticipants = billData.participants.map((p) =>
      p.userId === participantId
        ? {
            ...p,
            settled: true,
            settledAt: serverTimestamp(),
          }
        : p
    );

    // Check if all participants have settled
    const allSettled = updatedParticipants.every((p) => p.settled);

    // Update bill document
    batch.update(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "completed" : "pending",
      lastUpdated: serverTimestamp(),
    });

    // Create transaction record
    const transactionRef = doc(collection(db, "transactions"));
    batch.set(transactionRef, {
      billId,
      userId: participantId,
      amount: {
        original: parseFloat(amount),
        settlement: parseFloat(amount),
      },
      type: "settlement",
      status: "completed",
      createdAt: serverTimestamp(),
      settledAt: serverTimestamp(),
    });

    await batch.commit();

    return {
      success: true,
      status: allSettled ? "completed" : "pending",
    };
  } catch (error) {
    console.error("Error in markSettlementComplete:", error);
    throw error;
  }
};

// Bill Settlements and Transactions
export const settleBillShare = async (billId, participantId, amount) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const currentTimestamp = Timestamp.now();

    // Create a new transaction
    const transactionData = {
      billId,
      userId: participantId,
      amount: {
        original: parseFloat(amount.original),
        settlement: parseFloat(amount.settlement),
      },
      createdAt: serverTimestamp(),
      settledAt: serverTimestamp(),
      settledBy: participantId,
      billDescription: billData.description,
      type: "settlement",
      status: "completed",
    };

    // Add the transaction
    const transactionRef = await addDoc(
      collection(db, "transactions"),
      transactionData
    );

    // Update the bill participants
    const updatedParticipants = billData.participants.map((p) => {
      if (p.userId === participantId) {
        return {
          ...p,
          settled: true,
          settledAt: currentTimestamp,
          transactionId: transactionRef.id,
        };
      }
      return p;
    });

    const allSettled = updatedParticipants.every((p) => p.settled);

    await updateDoc(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "completed" : "pending",
      lastUpdated: serverTimestamp(),
    });

    return {
      success: true,
      transactionId: transactionRef.id,
      status: allSettled ? "completed" : "pending",
    };
  } catch (error) {
    console.error("Error in settleBillShare:", error);
    throw error;
  }
};

export const getUserTransactions = async (userId) => {
  try {
    const q = query(
      collection(db, "transactions"),
      where("userId", "==", userId),
      orderBy("settledAt", "desc")
    );

    const querySnapshot = await getDocs(q);

    // Get all transactions first
    const transactions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch associated bill details for each transaction
    const enrichedTransactions = await Promise.all(
      transactions.map(async (transaction) => {
        if (transaction.billId) {
          const billDoc = await getDoc(doc(db, "bills", transaction.billId));
          if (billDoc.exists()) {
            const billData = billDoc.data();
            return {
              ...transaction,
              billDetails: {
                splitType: billData.splitType,
                participants: billData.participants,
                paidBy: billData.paidBy,
                totalAmount: billData.amount,
                description: billData.description,
              },
            };
          }
        }
        return transaction;
      })
    );

    return enrichedTransactions;
  } catch (error) {
    console.error("Error fetching user transactions:", error);
    if (
      error.code === "failed-precondition" ||
      error.message.includes("requires an index")
    ) {
      throw new Error(
        "Setting up database indexes. Please try again in a few minutes."
      );
    }
    throw new Error("Failed to fetch transactions");
  }
};

export const getBillTransactions = async (billId) => {
  try {
    console.log("Fetching transactions for bill:", billId);

    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId),
      orderBy("createdAt", "desc")
    );

    const querySnapshot = await getDocs(transactionsQuery);
    const transactions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log("Found transactions:", transactions);
    return transactions;
  } catch (error) {
    console.error("Error fetching bill transactions:", error);
    throw error;
  }
};

export const getUserBillTransactions = async (userId, billId) => {
  try {
    const q = query(
      collection(db, "transactions"),
      where("userId", "==", userId),
      where("billId", "==", billId),
      orderBy("settledAt", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching user bill transactions:", error);
    throw new Error("Failed to fetch user bill transactions");
  }
};

// Helper function to calculate balances for a user
export const calculateUserBalances = async (userId) => {
  try {
    const bills = await getUserBills(userId);
    const balances = {};

    bills.forEach((bill) => {
      if (bill.paidBy === userId) {
        // Add amounts owed to the user
        bill.participants.forEach((participant) => {
          if (participant.userId !== userId && !participant.settled) {
            balances[participant.userId] = {
              original:
                (balances[participant.userId]?.original || 0) +
                participant.share.original,
              settlement:
                (balances[participant.userId]?.settlement || 0) +
                participant.share.settlement,
            };
          }
        });
      } else {
        // Subtract amounts the user owes
        const userParticipation = bill.participants.find(
          (p) => p.userId === userId
        );
        if (userParticipation && !userParticipation.settled) {
          balances[bill.paidBy] = {
            original:
              (balances[bill.paidBy]?.original || 0) -
              userParticipation.share.original,
            settlement:
              (balances[bill.paidBy]?.settlement || 0) -
              userParticipation.share.settlement,
          };
        }
      }
    });

    return balances;
  } catch (error) {
    console.error("Error calculating user balances:", error);
    throw error;
  }
};

export const calculateGroupSettlements = async (groupId) => {
  try {
    const settlements = await calculateDetailedGroupSettlements(groupId);
    return settlements;
  } catch (error) {
    console.error("Error in group settlements calculation:", error);
    throw error;
  }
};

export const getGroupSettlements = async (groupId) => {
  try {
    // First verify if the user has access to the group
    const groupRef = doc(db, "groups", groupId);
    const groupDoc = await getDoc(groupRef);

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    // Get the settlements document
    const settlementsRef = doc(db, "groups", groupId, "settlements", "current");
    const settlementDoc = await getDoc(settlementsRef);

    // If no settlements document exists yet, return default empty state
    if (!settlementDoc.exists()) {
      return {
        settlements: [],
        status: "no_settlements",
        updatedAt: null,
        totalSettlements: 0,
      };
    }

    const data = settlementDoc.data();

    // Enrich settlement data with user details if not already present
    const enrichedSettlements = await Promise.all(
      (data.settlements || []).map(async (settlement) => {
        if (!settlement.fromUser || !settlement.toUser) {
          const [fromUserDoc, toUserDoc] = await Promise.all([
            getDoc(doc(db, "users", settlement.from)),
            getDoc(doc(db, "users", settlement.to)),
          ]);

          return {
            ...settlement,
            fromUser: fromUserDoc.exists()
              ? {
                  userId: fromUserDoc.id,
                  displayName: fromUserDoc.data().displayName,
                  email: fromUserDoc.data().email,
                  photoURL: fromUserDoc.data().photoURL,
                }
              : null,
            toUser: toUserDoc.exists()
              ? {
                  userId: toUserDoc.id,
                  displayName: toUserDoc.data().displayName,
                  email: toUserDoc.data().email,
                  photoURL: toUserDoc.data().photoURL,
                }
              : null,
          };
        }
        return settlement;
      })
    );

    return {
      settlements: enrichedSettlements,
      status: data.status || "active",
      updatedAt: data.updatedAt,
      totalSettlements: data.totalSettlements || enrichedSettlements.length,
    };
  } catch (error) {
    console.error("Error fetching group settlements:", error);
    // Return empty state instead of throwing error
    return {
      settlements: [],
      status: "error",
      updatedAt: null,
      totalSettlements: 0,
    };
  }
};

export const markGroupSettlementComplete = async (
  groupId,
  fromUserId,
  toUserId,
  amount
) => {
  try {
    const settlementsRef = doc(db, "groups", groupId, "settlements", "current");
    const groupRef = doc(db, "groups", groupId);

    // Get both settlements and group data in parallel
    const [settlementDoc, groupDoc] = await Promise.all([
      getDoc(settlementsRef),
      getDoc(groupRef),
    ]);

    if (!settlementDoc.exists()) {
      throw new Error("Group settlements not found");
    }

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    const data = settlementDoc.data();
    const groupData = groupDoc.data();
    const settlements = data.settlements || [];

    // Find the specific settlement
    const settlementIndex = settlements.findIndex(
      (s) =>
        s.from === fromUserId &&
        s.to === toUserId &&
        Math.abs(s.amount - amount) < 0.01
    );

    if (settlementIndex === -1) {
      throw new Error("Settlement not found");
    }

    const batch = writeBatch(db);
    const completedSettlement = settlements[settlementIndex];
    const currentTimestamp = serverTimestamp();

    // 1. Create Settlement History Record
    const historyRef = doc(collection(db, "settlementHistory"));
    const historyData = {
      ...completedSettlement,
      groupId,
      groupName: groupData.name,
      completedAt: currentTimestamp,
      status: "completed",
      settledBy: fromUserId,
      type: "group_settlement",
      historyId: historyRef.id,
      lastUpdated: currentTimestamp,
      totalAmount: amount,
      relatedBills: completedSettlement.relatedBills || {},
    };
    batch.set(historyRef, historyData);

    // 2. Update Bills and Create Transactions
    const transactionPromises = [];
    const billUpdates = new Map();

    if (completedSettlement.relatedBills?.debtorBills) {
      for (const billInfo of completedSettlement.relatedBills.debtorBills) {
        const billRef = doc(db, "bills", billInfo.billId);
        const billDoc = await getDoc(billRef);

        if (billDoc.exists()) {
          const billData = billDoc.data();

          // Update bill participants
          const updatedParticipants = billData.participants.map((p) => {
            if (p.userId === fromUserId) {
              return {
                ...p,
                settled: true,
                settledAt: currentTimestamp,
              };
            }
            return p;
          });

          const allSettled = updatedParticipants.every(
            (p) => p.settled || p.userId === billData.paidBy
          );

          // Store bill updates
          billUpdates.set(billRef.id, {
            ref: billRef,
            data: {
              participants: updatedParticipants,
              status: allSettled ? "completed" : "pending",
              lastUpdated: currentTimestamp,
            },
          });

          // 3. Create Transaction Records (one for debtor, one for creditor)
          // Debtor Transaction (negative amount)
          const debtorTransactionRef = doc(collection(db, "transactions"));
          const debtorTransaction = {
            billId: billInfo.billId,
            groupId,
            userId: fromUserId,
            amount: {
              original: -billInfo.amount,
              settlement: -billInfo.amount,
            },
            createdAt: currentTimestamp,
            settledAt: currentTimestamp,
            settledBy: fromUserId,
            billDescription: billInfo.description || "Group Bill Payment",
            type: "payment",
            status: "completed",
            settlementHistoryId: historyRef.id,
          };
          transactionPromises.push({
            ref: debtorTransactionRef,
            data: debtorTransaction,
          });

          // Creditor Transaction (positive amount)
          const creditorTransactionRef = doc(collection(db, "transactions"));
          const creditorTransaction = {
            billId: billInfo.billId,
            groupId,
            userId: toUserId,
            amount: {
              original: billInfo.amount,
              settlement: billInfo.amount,
            },
            createdAt: currentTimestamp,
            settledAt: currentTimestamp,
            settledBy: fromUserId,
            billDescription: billInfo.description || "Group Bill Receipt",
            type: "receipt",
            status: "completed",
            settlementHistoryId: historyRef.id,
          };
          transactionPromises.push({
            ref: creditorTransactionRef,
            data: creditorTransaction,
          });
        }
      }
    }

    // 4. Update Bills in Batch
    billUpdates.forEach((update) => {
      batch.update(update.ref, update.data);
    });

    // 5. Add Transactions to Batch
    transactionPromises.forEach((transaction) => {
      batch.set(transaction.ref, transaction.data);
    });

    // 6. Update Group Stats
    const groupStats = {
      lastSettlementAt: currentTimestamp,
      totalSettlements: increment(1),
      activeSettlements: increment(-1),
      settledAmount: increment(amount),
    };
    batch.update(groupRef, groupStats);

    // 7. Update Current Settlements List
    const updatedSettlements = settlements.filter(
      (_, i) => i !== settlementIndex
    );
    batch.update(settlementsRef, {
      settlements: updatedSettlements,
      updatedAt: currentTimestamp,
      status: updatedSettlements.length === 0 ? "completed" : "active",
      totalSettlements: (data.totalSettlements || 0) + 1,
      completedSettlements: (data.completedSettlements || 0) + 1,
    });

    // Commit all changes
    await batch.commit();

    return {
      success: true,
      status: updatedSettlements.length === 0 ? "completed" : "active",
      settlementHistoryId: historyRef.id,
      updatedBills: Array.from(billUpdates.keys()),
      transactionCount: transactionPromises.length,
    };
  } catch (error) {
    console.error("Error in markGroupSettlementComplete:", error);
    throw error;
  }
};

export const verifySettlement = async (billId, participantId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const participant = billData.participants.find(
      (p) => p.userId === participantId
    );

    return participant?.settled === true;
  } catch (error) {
    console.error("Error verifying settlement:", error);
    throw error;
  }
};

// Helper function to check if a bill is a group bill
export const isGroupBill = (bill) => {
  return bill?.groupId != null;
};

export const handleSettlementComplete = async (bill, settlement) => {
  try {
    const billRef = doc(db, "bills", bill.id);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);
    const now = new Date();

    const sanitizeParticipantData = (participant) => {
      const sanitized = {
        ...participant,
        settledAt: null,
        share: participant.share || { original: 0, settlement: 0 },
      };

      delete sanitized.timestamp;
      delete sanitized.lastUpdated;

      return sanitized;
    };

    const payingParticipant = billData.participants.find(
      (p) => p.userId === settlement.from
    );
    const receivingParticipant = billData.participants.find(
      (p) => p.userId === settlement.to
    );

    const updatedParticipants = billData.participants.map((p) => {
      if (p.userId === settlement.from) {
        return {
          ...sanitizeParticipantData(p),
          settled: true,
          settledAt: now,
        };
      }
      return sanitizeParticipantData(p);
    });

    const allSettled = updatedParticipants.every(
      (p) => p.settled || p.userId === billData.paidBy
    );

    const transactionRef = doc(collection(db, "transactions"));
    const transactionData = {
      billId: bill.id,
      userId: settlement.from,
      type: "payment",
      status: "completed",
      amount: {
        original: parseFloat(settlement.amount),
        settlement: parseFloat(settlement.amount),
      },
      billDescription: billData.description || "Bill Payment",
      createdAt: now,
      settledAt: now,
      settledBy: settlement.from,
      groupId: bill.groupId || null,
      billDetails: {
        paidBy: billData.paidBy,
        totalAmount: billData.amount,
        splitType: billData.splitType,
        participants: billData.participants.map((p) => ({
          userId: p.userId,
          displayName: p.displayName || "",
          email: p.email || "",
          photoURL: p.photoURL || "",
          share: p.share || { original: 0, settlement: 0 },
          settled: p.settled || false,
        })),
      },
    };

    const billUpdate = {
      participants: updatedParticipants.map((p) => ({
        ...p,
        settledAt: p.settledAt ? p.settledAt : null,
      })),
      status: allSettled ? "completed" : "pending",
      lastUpdated: now,
    };

    batch.update(billRef, {
      participants: billUpdate.participants,
      status: billUpdate.status,
      lastUpdated: serverTimestamp(),
    });

    batch.set(transactionRef, {
      ...transactionData,
      createdAt: serverTimestamp(),
      settledAt: serverTimestamp(),
    });

    if (bill.groupId && allSettled) {
      const groupRef = doc(db, "groups", bill.groupId);
      batch.update(groupRef, {
        lastSettlementAt: serverTimestamp(),
        totalSettlements: increment(1),
        settledAmount: increment(settlement.amount),
      });
    }

    if (bill.groupId) {
      await logSettlementActivity(bill.groupId, {
        type: "settlement_completed",
        timestamp: serverTimestamp(),
        from: {
          userId: settlement.from,
          displayName: payingParticipant?.displayName || "",
          photoURL: payingParticipant?.photoURL || "",
          email: payingParticipant?.email || "",
        },
        to: {
          userId: settlement.to,
          displayName: receivingParticipant?.displayName || "",
          photoURL: receivingParticipant?.photoURL || "",
          email: receivingParticipant?.email || "",
        },
        amount: settlement.amount,
        billId: bill.id,
        billDescription: billData.description,
        settlementDetails: {
          originalAmount: bill.amount,
          splitType: bill.splitType,
          settledAmount: settlement.amount,
        },
      });
    }

    await batch.commit();

    return {
      success: true,
      status: allSettled ? "completed" : "pending",
      transactionId: transactionRef.id,
    };
  } catch (error) {
    console.error("Error in handleSettlementComplete:", error);
    throw error;
  }
};

export const logGroupActivity = async (groupId, activity) => {
  try {
    const activityRef = collection(db, "groups", groupId, "activities");
    await addDoc(activityRef, {
      ...activity,
      timestamp: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error logging activity:", error);
    throw error;
  }
};

export const getGroupActivities = async (groupId) => {
  try {
    const activitiesRef = collection(db, "groups", groupId, "activities");
    const q = query(activitiesRef, orderBy("timestamp", "desc"));
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching group activities:", error);
    throw error;
  }
};

export const logMemberAdded = async (groupId, { member, initiator }) => {
  await logGroupActivity(groupId, {
    type: "member_added",
    member,
    initiator,
  });
};

export const logMemberRemoved = async (
  groupId,
  { member, initiator, reason }
) => {
  await logGroupActivity(groupId, {
    type: "member_removed",
    member,
    initiator,
    reason,
  });
};

export const logBillCreated = async (groupId, { bill, initiator }) => {
  try {
    const activityRef = collection(db, "groups", groupId, "activities");

    const activityData = {
      type: "bill_created",
      billId: bill.id || "",
      billDescription: bill.description || "",
      billAmount: Number(bill.amount) || 0,
      billSplitType: bill.splitType || "equal",
      initiatorId: initiator.userId || "",
      initiatorName: initiator.displayName || "",
      initiatorPhoto: initiator.photoURL || "",
      billParticipants: bill.participants || [],
      billSpiltDetails: bill.splitDetails || [],
      billItems: bill.items || [],
      timestamp: serverTimestamp(),
      createdAt: serverTimestamp(),
    };

    await addDoc(activityRef, activityData);
  } catch (error) {
    console.error("Error logging bill activity:", error);
  }
};

export const logSettlementCompleted = async (
  groupId,
  { from, to, amount, billId }
) => {
  await logGroupActivity(groupId, {
    type: "settlement_completed",
    from,
    to,
    amount,
    billId,
  });
};

export const logGroupUpdated = async (groupId, { initiator, changes }) => {
  await logGroupActivity(groupId, {
    type: "group_updated",
    initiator,
    changes,
  });
};

export const logBillActivity = async (groupId, billData, type) => {
  try {
    const activity = {
      type: type,
      timestamp: serverTimestamp(),
      bill: {
        id: billData.id,
        description: billData.description,
        amount: billData.amount,
        paidBy: billData.paidBy,
        splitType: billData.splitType,
      },
      initiator: {
        userId: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
      },
    };
    await logGroupActivity(groupId, activity);
  } catch (error) {
    console.error("Error logging bill activity:", error);
  }
};

export const logSettlementActivity = async (groupId, activityData) => {
  try {
    const activityRef = collection(db, "groups", groupId, "activities");
    await addDoc(activityRef, {
      ...activityData,
      timestamp: serverTimestamp(),
      type: "settlement_completed",
    });
  } catch (error) {
    console.error("Error logging settlement activity:", error);
  }
};

export const logGroupDelete = async (groupId, groupData) => {
  await logGroupActivity(groupId, {
    type: "group_deleted",
    groupName: groupData.name,
    memberCount: groupData.members.length,
    initiator: {
      userId: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
    },
  });
};

export const logBillDelete = async (groupId, billData) => {
  await logGroupActivity(groupId, {
    type: "bill_deleted",
    bill: {
      id: billData.id,
      description: billData.description,
      amount: billData.amount,
      participants: billData.participants || [],
      splitType: billData.splitType || "equal",
      paidBy: billData.paidBy,
    },
    initiator: {
      userId: auth.currentUser.uid,
      displayName: auth.currentUser.displayName || "",
      photoURL: auth.currentUser.photoURL || "",
      email: auth.currentUser.email || "",
    },
    timestamp: serverTimestamp(),
    deletedAt: serverTimestamp(),
  });
};

export const logMemberAdd = async (groupId, newMember, addedBy) => {
  await logGroupActivity(groupId, {
    type: "member_added",
    member: {
      userId: newMember.userId,
      displayName: newMember.displayName,
      email: newMember.email,
    },
    initiator: {
      userId: addedBy,
      displayName: auth.currentUser.displayName,
    },
  });
};

export const logBillUpdated = async (groupId, { bill, initiator, changes }) => {
  try {
    const activityRef = collection(db, "groups", groupId, "activities");

    const activityData = {
      type: "bill_updated",
      billId: bill.id || "",
      billDescription: bill.description || "",
      billAmount: Number(bill.amount) || 0,
      billSplitType: bill.splitType || "equal",
      initiatorId: initiator.userId || "",
      initiatorName: initiator.displayName || "",
      initiatorPhoto: initiator.photoURL || "",
      billParticipants: bill.participants || [],
      billSpiltDetails: bill.splitDetails || [],
      billItems: bill.items || [],
      changes: changes,
      timestamp: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    await addDoc(activityRef, activityData);
  } catch (error) {
    console.error("Error logging bill update activity:", error);
  }
};

export const logGroupCreation = async (groupId, { group, creator }) => {
  try {
    const activityRef = collection(db, "groups", groupId, "activities");
    const activityData = {
      type: "group_created",
      timestamp: serverTimestamp(),
      creator: {
        userId: creator.userId,
        displayName: creator.displayName || "",
        photoURL: creator.photoURL || "",
        email: creator.email || "",
      },
      groupDetails: {
        name: group.name,
        memberCount: group.members.length,
        members: group.members.map((member) => ({
          userId: member.userId,
          displayName: member.displayName || "",
          photoURL: member.photoURL || "",
          email: member.email || "",
        })),
      },
    };

    await addDoc(activityRef, activityData);
  } catch (error) {
    console.error("Error logging group creation:", error);
  }
};

export const getSimplifiedGroupDebts = async (groupId, userId) => {
  try {
    const billsQuery = query(
      collection(db, "bills"),
      where("groupId", "==", groupId),
      where("status", "!=", "deleted"),
      orderBy("date", "asc")
    );

    const billsSnapshot = await getDocs(billsQuery);
    const bills = billsSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(bill => bill.amount && !isNaN(parseFloat(bill.amount)));

    // Create a map to store running balances
    const userBalanceMap = new Map();
    let totalAmount = 0;

    // Process bills in chronological order
    bills.forEach(bill => {
      const amount = parseFloat(bill.amount);
      if (isNaN(amount) || !bill.paidBy) return;

      totalAmount += amount;
      const perPersonShare = amount / bill.participants.length;

      // Initialize payer if not exists
      if (!userBalanceMap.has(bill.paidBy)) {
        const payer = bill.participants.find(p => p.userId === bill.paidBy);
        userBalanceMap.set(bill.paidBy, {
          balance: 0,
          totalPaid: 0,
          totalOwed: 0,
          bills: [],
          user: {
            userId: bill.paidBy,
            displayName: payer?.displayName || 'Unknown User',
            email: payer?.email || '',
            photoURL: payer?.photoURL || ''
          }
        });
      }

      // Update payer's balance: Add full amount paid, subtract their share
      const payerData = userBalanceMap.get(bill.paidBy);
      payerData.totalPaid += amount;
      payerData.totalOwed += perPersonShare; // Payer also owes their share
      payerData.balance = payerData.totalPaid - payerData.totalOwed;
      payerData.bills.push({
        billId: bill.id,
        description: bill.description || 'Unnamed Bill',
        amount: amount,
        date: bill.date,
        splitType: bill.splitType,
        type: 'paid',
        share: perPersonShare
      });

      // Process other participants
      bill.participants.forEach(participant => {
        if (participant.userId === bill.paidBy) return; // Skip payer as already processed

        if (!userBalanceMap.has(participant.userId)) {
          userBalanceMap.set(participant.userId, {
            balance: 0,
            totalPaid: 0,
            totalOwed: 0,
            bills: [],
            user: {
              userId: participant.userId,
              displayName: participant.displayName || 'Unknown User',
              email: participant.email || '',
              photoURL: participant.photoURL || ''
            }
          });
        }

        const participantData = userBalanceMap.get(participant.userId);
        participantData.totalOwed += perPersonShare;
        participantData.balance = participantData.totalPaid - participantData.totalOwed;
        participantData.bills.push({
          billId: bill.id,
          description: bill.description || 'Unnamed Bill',
          amount: perPersonShare,
          date: bill.date,
          splitType: bill.splitType,
          type: 'owed'
        });
      });
    });

    // Round all final balances to 2 decimal places
    userBalanceMap.forEach(userData => {
      userData.balance = Number(userData.balance.toFixed(2));
      userData.totalPaid = Number(userData.totalPaid.toFixed(2));
      userData.totalOwed = Number(userData.totalOwed.toFixed(2));
    });

    const balances = Array.from(userBalanceMap.values())
      .filter(userData => Math.abs(userData.balance) > 0.01);

    return {
      balances,
      totalAmount: Number(totalAmount.toFixed(2)),
      detailedBalances: Object.fromEntries(userBalanceMap),
      simplifiedTransactions: { 
        transactions: calculateMinimumTransactions([...balances]) 
      }
    };
  } catch (error) {
    console.error("Error getting simplified debts:", error);
    throw error;
  }
};

const calculateMinimumTransactions = (balances) => {
  // Create deep copies to avoid mutating original data
  const workingBalances = balances.map(b => ({
    ...b,
    remainingBalance: Number(Math.round(b.balance * 100) / 100) // Ensure 2 decimal precision
  }));

  console.log('Initial balances:', workingBalances.map(b => ({
    user: b.user.displayName,
    balance: b.remainingBalance,
    totalPaid: b.totalPaid,
    totalOwed: b.totalOwed
  })));

  // Separate debtors (negative balance) and creditors (positive balance)
  let debtors = workingBalances
    .filter(b => b.remainingBalance < -0.01)
    .sort((a, b) => a.remainingBalance - b.remainingBalance); // Most negative first

  let creditors = workingBalances
    .filter(b => b.remainingBalance > 0.01)
    .sort((a, b) => b.remainingBalance - a.remainingBalance); // Most positive first

  const transactions = [];

  while (debtors.length > 0 && creditors.length > 0) {
    const debtor = debtors[0];
    const creditor = creditors[0];

    // Calculate the optimal transfer amount (minimum of debt and credit)
    const transferAmount = Math.min(
      Math.abs(debtor.remainingBalance),
      creditor.remainingBalance
    );

    // Only process if amount is significant (> 0.01)
    if (transferAmount > 0.01) {
      const roundedAmount = Number(transferAmount.toFixed(2));

      console.log(`Processing settlement - From: ${debtor.user.displayName} (${debtor.remainingBalance}) To: ${creditor.user.displayName} (${creditor.remainingBalance}) Amount: ${roundedAmount}`);

      transactions.push({
        from: debtor.user.userId,
        fromUser: {
          userId: debtor.user.userId,
          displayName: debtor.user.displayName,
          email: debtor.user.email,
          photoURL: debtor.user.photoURL
        },
        to: creditor.user.userId,
        toUser: {
          userId: creditor.user.userId,
          displayName: creditor.user.displayName,
          email: creditor.user.email,
          photoURL: creditor.user.photoURL
        },
        amount: roundedAmount,
        relatedBills: {
          debtorBills: debtor.bills
            .filter(b => b.type === 'owed')
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(b => ({
              billId: b.billId,
              description: b.description,
              amount: Number(b.amount.toFixed(2)),
              date: b.date,
              splitType: b.splitType
            })),
          creditorBills: creditor.bills
            .filter(b => b.type === 'paid')
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(b => ({
              billId: b.billId,
              description: b.description,
              amount: Number(b.amount.toFixed(2)),
              date: b.date,
              splitType: b.splitType
            }))
        }
      });

      // Update remaining balances
      debtor.remainingBalance = Number((debtor.remainingBalance + roundedAmount).toFixed(2));
      creditor.remainingBalance = Number((creditor.remainingBalance - roundedAmount).toFixed(2));

      console.log(`Updated balances - ${debtor.user.displayName}: ${debtor.remainingBalance}, ${creditor.user.displayName}: ${creditor.remainingBalance}`);
    }

    // Remove settled parties (those with near-zero remaining balance)
    if (Math.abs(debtor.remainingBalance) <= 0.01) {
      debtors.shift();
      console.log(`Settled debtor removed: ${debtor.user.displayName}`);
    }
    if (Math.abs(creditor.remainingBalance) <= 0.01) {
      creditors.shift();
      console.log(`Settled creditor removed: ${creditor.user.displayName}`);
    }

    // Verification step - check total balances
    const totalDebt = debtors.reduce((sum, d) => sum + d.remainingBalance, 0);
    const totalCredit = creditors.reduce((sum, c) => sum + c.remainingBalance, 0);
    console.log(`Remaining balances - Total debt: ${totalDebt.toFixed(2)}, Total credit: ${totalCredit.toFixed(2)}`);
  }

  // Verify final settlements
  const finalVerification = transactions.reduce((acc, t) => {
    console.log(`Settlement verification: ${t.fromUser.displayName} -> ${t.toUser.displayName}: ${t.amount}`);
    return acc + t.amount;
  }, 0);

  console.log(`Total settled amount: ${finalVerification.toFixed(2)}`);
  return transactions;
};