import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  alpha,
  styled,
  CircularProgress,
  Tooltip,
  Collapse,
} from "@mui/material";
import {
  Refresh as ResendIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Schedule as PendingIcon,
} from "@mui/icons-material";
import { sendInviteEmail } from "../../backend-services/emailService";
import InviteCard from "./InviteCard";

const StatusIcon = styled(Box)(({ theme, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case "success":
        return theme.palette.success.main;
      case "error":
        return theme.palette.error.main;
      case "pending":
        return theme.palette.warning.main;
      default:
        return theme.palette.text.secondary;
    }
  };

  return {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: getStatusColor(),
    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem",
    },
  };
});

const ResendButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  padding: theme.spacing(0.5),
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
  marginTop: theme.spacing(0.5),
}));

const InviteList = ({ invites, onInviteUpdate }) => {
  const [resendingMap, setResendingMap] = useState({});
  const [errorMap, setErrorMap] = useState({});

  const handleResend = async (invite) => {
    setResendingMap((prev) => ({ ...prev, [invite.inviteId]: true }));
    setErrorMap((prev) => ({ ...prev, [invite.inviteId]: null }));

    try {
      await sendInviteEmail(invite);

      if (onInviteUpdate) {
        onInviteUpdate(invite.inviteId, {
          emailSent: true,
          emailSentAt: new Date(),
          emailError: null,
        });
      }
    } catch (error) {
      console.error("Error resending invite:", error);
      setErrorMap((prev) => ({
        ...prev,
        [invite.inviteId]: error.message || "Failed to send invite",
      }));
    } finally {
      setResendingMap((prev) => ({ ...prev, [invite.inviteId]: false }));
    }
  };

  const renderEmailStatus = (invite) => {
    const isResending = resendingMap[invite.inviteId];
    const error = errorMap[invite.inviteId] || invite.emailError;

    if (isResending) {
      return (
        <StatusIcon status="pending">
          <CircularProgress size={16} />
          <Typography variant="caption">Sending...</Typography>
        </StatusIcon>
      );
    }

    if (error) {
      return (
        <Box>
          <StatusIcon status="error">
            <ErrorIcon />
            <Typography variant="caption">Failed to send</Typography>
            <Tooltip title="Resend invite">
              <ResendButton
                size="small"
                onClick={() => handleResend(invite)}
                disabled={isResending}
              >
                <ResendIcon />
              </ResendButton>
            </Tooltip>
          </StatusIcon>
          <ErrorMessage>{error}</ErrorMessage>
        </Box>
      );
    }

    if (invite.emailSent) {
      return (
        <StatusIcon status="success">
          <CheckCircleIcon />
          <Typography variant="caption">
            Sent {invite.emailSentAt?.toDate().toLocaleDateString()}
          </Typography>
        </StatusIcon>
      );
    }

    return (
      <StatusIcon status="pending">
        <PendingIcon />
        <Typography variant="caption">Pending</Typography>
      </StatusIcon>
    );
  };

  if (!invites?.length) {
    return (
      <Typography color="text.secondary" align="center">
        No pending invites
      </Typography>
    );
  }

  return (
    <Box>
      {invites.map((invite) => (
        <Collapse key={invite.inviteId} in={true}>
          <Box sx={{ mb: 2 }}>
            <InviteCard
              invite={invite}
              emailStatus={renderEmailStatus(invite)}
              showActions={invite.status === "pending"}
              onResend={() => handleResend(invite)}
              isResending={resendingMap[invite.inviteId]}
            />
          </Box>
        </Collapse>
      ))}
    </Box>
  );
};

export default InviteList;
