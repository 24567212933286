import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  alpha,
  styled,
  keyframes,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import DashboardStats from "./DashboardStats";
import {
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../backend-services/firebase";

const gradientMove = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

const WelcomeCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  backgroundSize: "200% 200%",
  animation: `${gradientMove} 15s ease infinite`,
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(4),
  color: theme.palette.common.white,
  position: "relative",
  overflow: "hidden",
  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
}));

const ContentSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: "grid",
  gap: theme.spacing(4),
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));

const DashboardPage = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const firstName = user?.displayName?.split(" ")[0] || "there";
  const [realtimeStats, setRealtimeStats] = useState(null);

  // Set up real-time listener for bills
  // Inside DashboardPage.js

  useEffect(() => {
    if (!user?.uid) return;
  
    let isMounted = true;
  
    const billsQuery = query(
      collection(db, "bills"),
      where("participantUserIds", "array-contains", user.uid),
      where("status", "!=", "deleted")
    );
  
    const unsubscribe = onSnapshot(billsQuery, async (snapshot) => {
      if (!isMounted) return;
  
      try {
        let settledBills = 0;
        let pendingBills = 0;
        let totalSpends = 0;
        let totalToReceive = 0;
        let totalToPay = 0;
  
        const transactionsQuery = query(
          collection(db, "transactions"),
          where("userId", "==", user.uid),
          where("status", "==", "completed")
        );
        
        const [bills, transactionSnapshot] = await Promise.all([
          Promise.all(snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))),
          getDocs(transactionsQuery)
        ]);
  
        if (!isMounted) return;
  
        bills.forEach(bill => {
          const userParticipant = bill.participants.find(p => p.userId === user.uid);
          
          if (bill.paidBy === user.uid) {
            totalSpends += parseFloat(bill.amount || 0);
          } else if (userParticipant) {
            totalSpends += parseFloat(userParticipant.share?.original || 0);
          }
  
          if (userParticipant?.settled || bill.paidBy === user.uid) {
            settledBills++;
          } else {
            pendingBills++;
          }
  
          if (bill.paidBy === user.uid) {
            bill.participants.forEach(participant => {
              if (participant.userId !== user.uid && !participant.settled) {
                totalToReceive += parseFloat(participant.share?.original || 0);
              }
            });
          } else if (userParticipant && !userParticipant.settled) {
            totalToPay += parseFloat(userParticipant.share?.original || 0);
          }
        });
  
        const transactions = transactionSnapshot.docs.map(doc => doc.data());
        transactions.forEach(transaction => {
          const amount = Math.abs(parseFloat(transaction.amount?.original || 0));
          if (transaction.type === "payment") {
            totalToPay = Math.max(0, totalToPay - amount);
          } else if (transaction.type === "receipt") {
            totalToReceive = Math.max(0, totalToReceive - amount);
          }
        });
  
        if (!isMounted) return;
  
        setRealtimeStats({
          totalSpends,
          totalReceivables: totalToReceive,
          totalToPay,
          settledBills,
          pendingBills,
          totalBills: bills.length,
          settlementProgress: bills.length > 0 ? (settledBills / bills.length) * 100 : 0,
          needsSettlement: totalToPay > 0 || totalToReceive > 0
        });
      } catch (error) {
        console.error("Error processing bills:", error);
      }
    });
  
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [user?.uid]);

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  };

  return (
    <ResponsiveContainer maxWidth="lg">
      <DashboardStats stats={realtimeStats} />
    </ResponsiveContainer>
  );
};

export default DashboardPage;
