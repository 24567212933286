import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  Select,
  MenuItem,
  styled,
  alpha,
  Avatar,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import UserSearch from "./UserSearch";
import BillParticipantsTree from "./BillParticipantsTree";
import ItemSplitInput from "./ItemSplitInput";
import { useBillManagement } from "../hooks/useBillManagement";
import { useTheme } from "@emotion/react";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";
import {
  createGroup,
  getGroupById,
  db,
  logBillActivity,
} from "../backend-services/firebase";
import { query, collection, where, getDocs, orderBy } from "firebase/firestore";
import GroupPreview from "./GroupPreview";
import CategorySelect from "./CategorySelect";

// Add keyframe animation for success icon
const GlobalStyles = styled("style")({
  "@keyframes pop-in": {
    "0%": {
      transform: "scale(0)",
      opacity: 0,
    },
    "70%": {
      transform: "scale(1.2)",
      opacity: 0.7,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
});

export const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "800px",
  padding: theme.spacing(3),
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const BackButtonRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
});

export const BackButton = styled(Button)({
  padding: "4px",
  minWidth: "unset",
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover": {
    background: "none",
    color: "rgba(255, 255, 255, 0.9)",
  },
});

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "24px",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
}));

export const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

export const FormField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:last-child": {
    marginBottom: 0,
  },
}));

export const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  "& .MuiSelect-select": {
    padding: "12px 16px",
  },
  "& fieldset": {
    border: "none",
  },
}));

export const AddBillButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  width: "100%",
  padding: "12px",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    color: theme.palette.common.black,
  },
}));

const AddBill = ({ isAutoGroup = false }) => {
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [existingGroups, setExistingGroups] = useState([]);
  const theme = useTheme();
  const {
    billData,
    billError,
    isCreatingBill,
    setIsCreatingBill,
    participantShares,
    handleBillDataChange,
    handleParticipantChange,
    handleSplitValueChange,
    createSingleBill,
    canSubmitBill,
    handleSplitTypeChange,
    handleItemAdd,
    handleItemRemove,
    calculateTotalAmount,
    validateSplitValues,
    validationMessages,
    setBillError,
    updateBillData,
    setBillData,
  } = useBillManagement();

  const validateGroupParticipants = (participants) => {
    if (!group) return true;

    return participants.every((participant) =>
      group.members.some((member) => member.userId === participant.userId)
    );
  };

  const handleGroupParticipantChange = (event, newParticipants) => {
    if (!validateGroupParticipants(newParticipants)) {
      setBillError("Only group members can be added as participants");
      return;
    }
    handleParticipantChange(event, newParticipants);
  };

  const handleBack = () => {
    if (groupId) {
      navigate(`/groups/${groupId}`);
    } else {
      navigate("/bills");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsCreatingBill(true);
      setBillError(null);

      const result = await createSingleBill(groupId);

      if (result.success) {
        setSuccessMessage("Bill created successfully!");
        await logBillActivity(
          result.groupId,
          { id: result.id },
          "bill_created"
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (groupId) {
          navigate(`/groups/${groupId}`);
        } else if (result.groupId) {
          navigate(`/groups/${result.groupId}`);
        } else {
          navigate("/bills");
        }
      }
    } catch (error) {
      setBillError(error.message);
    } finally {
      setIsCreatingBill(false);
    }
  };

  const shouldShowDistribution = () => {
    if (billData.splitType === "items") {
      return billData.items?.length > 0 && billData.paidBy;
    }
    return billData.amount > 0 && billData.paidBy && validateSplitValues();
  };

  const getPageTitle = () => {
    if (isAutoGroup) {
      return "Add Bill with New Group";
    }
    return groupId && group ? `Add Bill to ${group.name}` : "Add Bill";
  };

  const generateGroupName = (participants) => {
    const names = participants
      .map((p) => p.displayName || p.email?.split("@")[0])
      .slice(0, 3);

    if (names.length <= 2) {
      return `${names.join(" & ")}'s Group`;
    }

    return `${names[0]}, ${names[1]} & ${names.length - 2} other${
      names.length > 3 ? "s" : ""
    }`;
  };

  const createGroupAndAddBill = async () => {
    try {
      setIsCreatingGroup(true);
      setBillError(null);

      // Create group first
      const groupData = {
        name: generateGroupName(billData.participants),
        createdBy: user.uid,
        members: billData.participants,
      };

      const groupId = await createGroup(groupData);

      // Then create the bill with the new group
      const billResult = await createSingleBill(groupId);

      if (billResult.success) {
        setSuccessMessage("Bill created successfully!");
        setTimeout(() => {
          navigate(`/groups/${groupId}`);
        }, 1500);
      }
    } catch (error) {
      console.error("Error creating group and bill:", error);
      setBillError(error.message || "Failed to create bill and group");
    } finally {
      setIsCreatingGroup(false);
    }
  };

  const isLoading = isCreatingBill || isCreatingGroup;

  useEffect(() => {
    const fetchGroupDetails = async () => {
      if (!groupId) return;

      try {
        setLoadingGroup(true);
        const groupDoc = await getGroupById(groupId);
        setGroup(groupDoc);

        // Use updateBillData instead of setBillData
        if (groupDoc?.members?.length) {
          updateBillData({
            participants: groupDoc.members,
          });
        }
      } catch (error) {
        console.error("Error fetching group:", error);
        setBillError("Failed to load group details");
      } finally {
        setLoadingGroup(false);
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  useEffect(() => {
    const fetchMatchingGroups = async () => {
      if (
        !billData.participants ||
        billData.participants.length < 2 ||
        !user?.uid
      ) {
        setExistingGroups([]);
        return;
      }

      setLoadingGroups(true);
      try {
        const participantIds = billData.participants.map((p) => p.userId);

        const groupsQuery = query(
          collection(db, "groups"),
          where("memberUserIds", "array-contains", user.uid),
          where("status", "!=", "deleted"),
          orderBy("createdAt", "desc")
        );

        const snapshot = await getDocs(groupsQuery);
        const matchingGroups = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((group) => {
            if (!Array.isArray(group.members)) return false;

            const groupMemberIds = [
              ...new Set(group.members.map((m) => m.userId)),
            ];

            return participantIds.every((id) => groupMemberIds.includes(id));
          })
          .sort(
            (a, b) =>
              (b.createdAt?.toMillis() || 0) - (a.createdAt?.toMillis() || 0)
          );

        setExistingGroups(matchingGroups);
      } catch (error) {
        console.error("Error fetching matching groups:", error);
        setBillError("Failed to load existing groups");
      } finally {
        setLoadingGroups(false);
      }
    };

    fetchMatchingGroups();
  }, [billData.participants, user?.uid]);

  if (groupId && loadingGroup) {
    return (
      <PageContainer maxWidth="md">
        <BackButtonRow>
          <BackButton
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            disableRipple
          >
            Back
          </BackButton>
        </BackButtonRow>
        <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  return (
    <>
      <GlobalStyles />
      <PageContainer maxWidth="md">
        <BackButtonRow>
          <BackButton
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            disableRipple
          >
            Back
          </BackButton>
        </BackButtonRow>

        <PageTitle>{getPageTitle()}</PageTitle>

        <FormCard sx={{ position: "relative" }}>
          {/* Main Form Content */}
          <Box sx={{ opacity: isCreatingBill ? 0.3 : 1 }}>
            {/* Bill Name Input */}
            <FormField>
              <StyledInput
                fullWidth
                placeholder="Bill Name"
                value={billData.name}
                onChange={(e) => handleBillDataChange("name")(e)}
                error={Boolean(validationMessages.name)}
                helperText={validationMessages.name}
              />
            </FormField>

            {/* Participants Selection */}
            <FormField>
              <UserSearch
                multiple
                value={billData.participants}
                onChange={
                  groupId
                    ? handleGroupParticipantChange
                    : handleParticipantChange
                }
                excludeUsers={[
                  {
                    userId: user?.uid,
                    email: user?.email,
                    displayName: user?.displayName,
                    photoURL: user?.photoURL,
                  },
                ]}
                showRecent={false}
                placeholder="Search and add users..."
                error={Boolean(validationMessages.participants)}
                helperText={validationMessages.participants}
                isGroupContext={!!groupId}
                groupMembers={group?.members || []}
                disabled={loadingGroup}
              />
              {billError && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1, display: "block" }}
                >
                  {billError}
                </Typography>
              )}
            </FormField>

            {/* Existing Group Selection */}
            <FormField>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Add to Existing Group
              </Typography>
              {loadingGroups ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "text.secondary",
                  }}
                >
                  <CircularProgress size={20} />
                  <Typography>Loading groups...</Typography>
                </Box>
              ) : existingGroups.length > 0 ? (
                <FormControl fullWidth>
                  <StyledSelect
                    value={billData.groupId || ""} // Changed from groupId to billData.groupId
                    onChange={(e) => {
                      const selectedGroupId = e.target.value;
                      console.log("Selected Group ID:", selectedGroupId); // Debug log

                      if (selectedGroupId) {
                        const selectedGroup = existingGroups.find(
                          (g) => g.id === selectedGroupId
                        );
                        if (selectedGroup) {
                          console.log("Found Selected Group:", selectedGroup); // Debug log
                          setBillData((prev) => ({
                            ...prev,
                            groupId: selectedGroupId,
                            participants: selectedGroup.members,
                          }));
                        }
                      } else {
                        setBillData((prev) => ({
                          ...prev,
                          groupId: null,
                        }));
                      }
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">Create New Group</MenuItem>
                    {existingGroups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography>{group.name}</Typography>
                          <Chip
                            size="small"
                            label={`${group.members.length} members`}
                            sx={{ ml: 1 }}
                          />
                        </Box>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No matching groups found. A new group will be created.
                </Typography>
              )}
            </FormField>

            {groupId && (
              <GroupPreview
                group={existingGroups.find((g) => g.id === groupId)}
              />
            )}

            {/* Paid By Selection */}
            <FormField>
              <FormControl fullWidth>
                <StyledSelect
                  value={billData.paidBy}
                  onChange={(e) => handleBillDataChange("paidBy")(e)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) return "Paid By";
                    const payer = billData.participants.find(
                      (p) => p.userId === selected
                    );
                    return (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={payer?.photoURL || "/api/placeholder/32/32"}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography>
                          {payer?.displayName || payer?.email}
                        </Typography>
                      </Box>
                    );
                  }}
                  error={Boolean(billError && !billData.paidBy)}
                >
                  {billData.participants.map((p) => (
                    <MenuItem key={p.userId} value={p.userId}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={p.photoURL || "/api/placeholder/32/32"}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography>{p.displayName || p.email}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </FormField>

            {/* Split Type Selection */}
            <FormField>
              <FormControl fullWidth>
                <StyledSelect
                  value={billData.splitType}
                  onChange={(e) => {
                    handleBillDataChange("splitType")(e);
                    handleSplitTypeChange(e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem value="equal">Split Equally</MenuItem>
                  <MenuItem value="shares">Split by Shares</MenuItem>
                  <MenuItem value="percentage">Split by Percentage</MenuItem>
                  <MenuItem value="items">Split by Items</MenuItem>
                </StyledSelect>
              </FormControl>
            </FormField>

            <FormField>
              <CategorySelect
                value={billData.category}
                onChange={(e) => handleBillDataChange("category")(e)}
                description={billData.name}
              />
            </FormField>

            {/* Amount Input (for non-item splits) */}
            {billData.splitType !== "items" && (
              <FormField>
                <StyledInput
                  fullWidth
                  type="number"
                  placeholder="Amount"
                  value={billData.amount}
                  onChange={(e) => handleBillDataChange("amount")(e)}
                />
              </FormField>
            )}

            {/* Share Distribution Inputs */}
            {billData.splitType === "shares" && (
              <FormField>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      sx={{ mb: 1 }}
                    >
                      Share Distribution
                    </Typography>
                  </Grid>
                  {billData.participants.map((p) => (
                    <Grid item xs={12} key={p.userId}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <StyledInput
                          fullWidth
                          type="number"
                          inputProps={{
                            min: 1,
                            step: 1,
                            style: { textAlign: "center" },
                          }}
                          value={participantShares[p.userId]?.shares || 1}
                          onChange={(e) => {
                            handleSplitValueChange(
                              p.userId,
                              parseInt(e.target.value) || 1
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                  minWidth: "150px",
                                  px: 2,
                                }}
                              >
                                <Avatar
                                  src={p.photoURL || "/api/placeholder/32/32"}
                                  sx={{ width: 24, height: 24 }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {p.displayName || p.email}
                                </Typography>
                              </Box>
                            ),
                            endAdornment: (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                shares
                              </Typography>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </FormField>
            )}

            {/* Percentage Distribution Inputs */}
            {billData.splitType === "percentage" && (
              <FormField>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        Percentage Distribution
                      </Typography>
                      <Typography
                        variant="caption"
                        color={
                          validateSplitValues() ? "success.main" : "error.main"
                        }
                        sx={{ fontWeight: 500 }}
                      >
                        Total:{" "}
                        {Object.values(participantShares)
                          .reduce(
                            (sum, share) =>
                              sum + (parseFloat(share?.percentage) || 0),
                            0
                          )
                          .toFixed(1)}
                        %
                      </Typography>
                    </Box>
                  </Grid>
                  {billData.participants.map((p) => (
                    <Grid item xs={12} key={p.userId}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          bgcolor: alpha(theme.palette.background.paper, 0.1),
                          borderRadius: 1,
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            flex: 1,
                          }}
                        >
                          <Avatar
                            src={p.photoURL || "/api/placeholder/32/32"}
                            sx={{ width: 32, height: 32 }}
                          />
                          <Typography sx={{ minWidth: 120 }}>
                            {p.displayName || p.email}
                          </Typography>
                        </Box>
                        <StyledInput
                          type="number"
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: 0.1,
                            style: {
                              textAlign: "center",
                              padding: "8px 12px",
                            },
                          }}
                          value={participantShares[p.userId]?.percentage || 0}
                          onChange={(e) => {
                            const value = Math.min(
                              100,
                              Math.max(0, parseFloat(e.target.value) || 0)
                            );
                            handleSplitValueChange(p.userId, value);
                          }}
                          sx={{
                            width: 120,
                            "& .MuiOutlinedInput-root": {
                              bgcolor: alpha(
                                theme.palette.background.paper,
                                0.2
                              ),
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ ml: 1 }}
                              >
                                %
                              </Typography>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </FormField>
            )}

            {/* Items Split Input */}
            {billData.splitType === "items" && (
              <FormField>
                <ItemSplitInput
                  billParticipants={billData.participants}
                  items={billData.items || []}
                  onAddItem={handleItemAdd}
                  onRemoveItem={handleItemRemove}
                />
              </FormField>
            )}

            {/* Payment Distribution Preview */}
            {shouldShowDistribution() && (
              <FormField>
                <BillParticipantsTree
                  paidBy={billData.participants.find(
                    (p) => p.userId === billData.paidBy
                  )}
                  participants={billData.participants}
                  amount={calculateTotalAmount()}
                  splitType={billData.splitType}
                  shares={billData.shares}
                  items={billData.items}
                />
              </FormField>
            )}

            {/* Error Display */}
            {billData.splitType === "percentage" && (
              <Box sx={{ mt: 1, color: "error.main" }}>
                {validationMessages.percentage}
              </Box>
            )}
            {billData.splitType === "shares" && validationMessages.shares && (
              <Box sx={{ mt: 1, color: "error.main" }}>
                {validationMessages.shares}
              </Box>
            )}
            {billData.splitType === "items" && validationMessages.items && (
              <Box sx={{ mt: 1, color: "error.main" }}>
                {validationMessages.items}
              </Box>
            )}
          </Box>
          {/* Submit Button */}
          <AddBillButton
            variant="contained"
            disabled={!canSubmitBill || isLoading}
            onClick={handleSubmit}
            sx={{ position: "relative", mt: 2 }}
          >
            {isLoading ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                {isCreatingGroup ? "Creating Group..." : "Creating Bill..."}
              </Box>
            ) : isAutoGroup ? (
              "Create Group & Add Bill"
            ) : (
              "Add Bill"
            )}
          </AddBillButton>
        </FormCard>
        {/* Success Snackbar */}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage("")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSuccessMessage("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
        {/* Error Snackbar */}
        <Snackbar
          open={Boolean(billError)}
          autoHideDuration={6000}
          onClose={() => setBillError("")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setBillError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {billError}
          </Alert>
        </Snackbar>
      </PageContainer>
    </>
  );
};

export default AddBill;
