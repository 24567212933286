import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  alpha,
  styled,
  CircularProgress,
  Alert,
  IconButton,
  Avatar,
  useMediaQuery,
  Slide,
  useTheme,
} from "@mui/material";
import {
  Close as CloseIcon,
  PersonRemove as PersonRemoveIcon,
} from "@mui/icons-material";
import {
  doc,
  updateDoc,
  arrayRemove,
  writeBatch,
  query,
  collection,
  where,
  getDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  db,
  auth,
  logGroupActivity,
  logGroupUpdated,
  logMemberRemoved,
} from "../backend-services/firebase";
import { useAuth } from "../contexts/AuthContext";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      position: "fixed",
      bottom: 0,
      borderRadius: `${theme.shape.borderRadius * 2}px ${
        theme.shape.borderRadius * 2
      }px 0 0`,
      maxHeight: "90vh",
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    },
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
      maxWidth: "500px",
      width: "100%",
      minHeight: "100vh",
      position: "fixed",
      right: 0,
      margin: 0,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiBackdrop-root": {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      backdropFilter: "blur(8px)",
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  "& .MuiTypography-root": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  overflowY: "auto",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
  },
}));

const MemberCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
  },
  "&:last-child": {
    marginBottom: 0,
  },
}));

const ActionButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  "&.MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  gap: theme.spacing(1),
}));

const EditGroupModal = ({ open, onClose, group, onUpdate }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [groupName, setGroupName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] =
    useState(false);

  const isCreator = group?.createdBy === user?.uid;

  const handleOpenRemoveMember = (member) => {
    setMemberToRemove(member);
    setIsRemoveMemberDialogOpen(true);
  };

  useEffect(() => {
    if (group) {
      setGroupName(group.name);
    }
  }, [group]);

  const isValidMemberRemoval = (member) => {
    if (!group || !member) return false;
    if (member.userId === group.createdBy) return false;
    if (!auth.currentUser) return false;
    if (auth.currentUser.uid !== group.createdBy) return false;
    return true;
  };

  const validateGroupName = (name) => {
    if (!name?.trim()) {
      return "Group name is required";
    }
    if (name.length < 3) {
      return "Group name must be at least 3 characters";
    }
    if (name.length > 50) {
      return "Group name cannot exceed 50 characters";
    }
    if (!/^[\w\s\-.,!?&()]+$/i.test(name)) {
      return "Group name contains invalid characters";
    }
    return "";
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setGroupName(newName);
    setNameError(validateGroupName(newName));
  };

  const handleRemoveMember = async (member) => {
    if (!isValidMemberRemoval(member)) {
      setError("Cannot remove this member");
      return;
    }
    if (!group || loading) return;

    try {
      setLoading(true);
      setError("");

      const currentUser = auth.currentUser;
      if (!currentUser || currentUser.uid !== group.createdBy) {
        throw new Error("Only group admin can remove members");
      }

      const groupRef = doc(db, "groups", group.id);

      const updatedMembers = group.members.filter(
        (m) => m.userId !== member.userId
      );
      const updatedMemberUserIds = group.memberUserIds.filter(
        (id) => id !== member.userId
      );

      await updateDoc(groupRef, {
        members: updatedMembers,
        memberUserIds: updatedMemberUserIds,
        updatedAt: serverTimestamp(),
      });

      await logMemberRemoved(group.id, {
        member: {
          userId: member.userId,
          displayName: member.displayName || "",
          email: member.email || "",
          photoURL: member.photoURL || "",
        },
        initiator: {
          userId: user.uid,
          displayName: user?.displayName || "",
          photoURL: user?.photoURL || "",
        },
        reason: "Removed by group admin",
      });

      if (onUpdate) {
        onUpdate();
      }
    } catch (err) {
      console.error("Error removing member:", err);
      setError(err.message || "Failed to remove member");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!group || loading) return;

    const nameValidation = validateGroupName(groupName);
    if (nameValidation) {
      setNameError(nameValidation);
      return;
    }

    try {
      setLoading(true);
      setError("");

      // Track changes
      const changes = {};

      // Check if name was changed (use trimmed values for both)
      const trimmedNewName = groupName.trim();
      const trimmedOldName = group.name.trim();

      if (trimmedNewName !== trimmedOldName) {
        changes.name = {
          previous: trimmedOldName,
          current: trimmedNewName,
        };
      }

      // Remove the condition checking changes length - we'll update anyway
      const groupRef = doc(db, "groups", group.id);
      await updateDoc(groupRef, {
        name: trimmedNewName,
        updatedAt: serverTimestamp(),
        lastModifiedBy: user.uid,
      });

      // Log the update even if only metadata changed
      await logGroupUpdated(group.id, {
        initiator: {
          userId: user.uid,
          displayName: user?.displayName || "",
          photoURL: user?.photoURL || "",
        },
        changes: Object.keys(changes).length > 0 ? changes : { general: true },
      });

      if (onUpdate) {
        onUpdate();
      }
      onClose();
    } catch (err) {
      console.error("Error updating group:", err);
      setError(err.message || "Failed to update group");
    } finally {
      setLoading(false);
    }
  };

  if (!group) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Slide}
      TransitionProps={{
        direction: isMobile ? "up" : "left",
      }}
    >
      <StyledDialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Edit Group</Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ color: "text.secondary" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>

      <StyledDialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mt: 2 }}>
          <StyledTextField
            fullWidth
            label="Group Name"
            value={groupName}
            onChange={handleNameChange}
            error={Boolean(nameError)}
            helperText={nameError}
            disabled={loading}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Members
          </Typography>
          {group?.members.map((member) => (
            <MemberCard key={member.userId}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar src={member.photoURL || "/api/placeholder/40/40"} />
                <Box>
                  <Typography variant="subtitle2">
                    {member.displayName || member.email}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {member.email}
                  </Typography>
                </Box>
              </Box>
              {isCreator && member.userId !== user?.uid && (
                <IconButton
                  onClick={() => handleOpenRemoveMember(member)}
                  color="error"
                  size="small"
                >
                  <PersonRemoveIcon />
                </IconButton>
              )}
            </MemberCard>
          ))}
        </Box>
      </StyledDialogContent>

      <StyledDialogActions>
        <ActionButton
          onClick={onClose}
          disabled={loading}
          variant="text"
          color="inherit"
        >
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || Boolean(nameError)}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? "Updating..." : "Update Group"}
        </ActionButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default EditGroupModal;
