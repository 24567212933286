import React from "react";
import {
  Box,
  Typography,
  Avatar,
  alpha,
  styled,
  Paper,
  CircularProgress,
  Alert,
  AvatarGroup,
  Tooltip,
  Chip,
  useTheme,
} from "@mui/material";
import {
  Group as GroupIcon,
  Receipt as ReceiptIcon,
  Edit as EditIcon,
  PersonAdd as PersonAddIcon,
  PersonRemove as PersonRemoveIcon,
  AccountBalance as SettlementIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { motion } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

const TimelineContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const MobileActivityCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const ActivityItem = styled(TimelineItem)(({ theme }) => ({
  "&::before": {
    display: "none",
  },
}));

const ActivityContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  marginBottom: theme.spacing(2),
}));

const TimeStamp = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.secondary, 0.8),
  fontSize: "0.75rem",
  marginTop: theme.spacing(1),
}));

const getActivityIcon = (type) => {
  switch (type) {
    case "group_created":
      return <GroupIcon />;
    case "member_added":
      return <PersonAddIcon />;
    case "member_removed":
      return <PersonRemoveIcon />;
    case "bill_created":
      return <ReceiptIcon />;
    case "group_updated":
      return <EditIcon />;
    case "settlement_completed":
      return <SettlementIcon />;
    default:
      return <GroupIcon />;
  }
};

const getActivityColor = (theme, type) => {
  switch (type) {
    case "group_created":
      return theme.palette.primary.main;
    case "member_added":
      return theme.palette.success.main;
    case "member_removed":
      return theme.palette.error.main;
    case "bill_created":
      return theme.palette.primary.main;
    case "group_updated":
      return theme.palette.warning.main;
    case "settlement_completed":
      return theme.palette.secondary.main;
    default:
      return theme.palette.info.main;
  }
};

const GroupActivityTimeline = ({ activities, loading, error }) => {
  const { userData } = useAuth();
  const theme = useTheme();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";

  const formatCurrency = (value) => {
    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(value);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!activities?.length) {
    return (
      <Box sx={{ textAlign: "center", p: 4 }}>
        <Typography variant="body1" color="text.secondary">
          No activity to show
        </Typography>
      </Box>
    );
  }

  const ActivityCard = ({ activity }) => {
    const formatActivityContent = () => {
      if (!activity) return null;

      switch (activity.type) {
        case "bill_created":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* Right side - Content */}
                <Box sx={{ flex: 1 }}>
                  {/* Header with initiator info */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={activity.initiatorPhoto || "/api/placeholder/32/32"}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.initiatorName || "Someone"} added a new bill
                    </Typography>
                  </Box>

                  {/* Bill details */}
                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.3),
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="500">
                        {activity.billDescription || "Unnamed bill"}
                      </Typography>
                      <Chip
                        label={activity.billSplitType || "equal"}
                        size="small"
                        sx={{
                          bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, 0.1),
                          color: "primary.main",
                          height: 24,
                          "& .MuiChip-label": {
                            px: 1,
                            fontSize: "0.75rem",
                            fontWeight: 500,
                          },
                        }}
                      />
                    </Box>

                    {/* Payment Distribution */}
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          Total Amount
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary.main"
                          fontWeight="600"
                        >
                          {formatCurrency(activity.billAmount || 0)}
                        </Typography>
                      </Box>

                      {activity.billSplitType === "equal" && (
                        <Typography variant="caption" color="text.secondary">
                          {formatCurrency(
                            (activity.billAmount || 0) /
                              (activity.billParticipants?.length || 1)
                          )}{" "}
                          per person
                        </Typography>
                      )}

                      {activity.billSplitType === "percentage" &&
                        activity.billSpiltDetails && (
                          <Box sx={{ mt: 1 }}>
                            {activity.billParticipants.map((participant) => {
                              const percentage =
                                activity.billSpiltDetails[participant.userId]
                                  ?.percentage || 0;
                              const amount =
                                (activity.billAmount * percentage) / 100;
                              return (
                                <Box
                                  key={participant.userId}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Avatar
                                      src={
                                        participant.photoURL ||
                                        "/api/placeholder/20/20"
                                      }
                                      sx={{ width: 20, height: 20 }}
                                    />
                                    <Typography variant="caption">
                                      {percentage}%
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {formatCurrency(amount)}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}

                      {activity.billSplitType === "items" &&
                        activity.billItems?.length > 0 && (
                          <Box sx={{ mt: 1 }}>
                            {activity.billItems.map((item, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mb: 0.5,
                                }}
                              >
                                <Typography variant="caption">
                                  {item.name} × {item.quantity}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {formatCurrency(item.totalAmount)}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                    </Box>

                    <Box sx={{ mt: 2, display: "inline-block" }}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          "& .MuiAvatar-root": {
                            width: 28,
                            height: 28,
                            fontSize: "0.75rem",
                            border: "2px solid",
                            borderColor: "background.paper",
                          },
                        }}
                      >
                        {activity.billParticipants.map((participant) => (
                          <Tooltip
                            key={participant.userId}
                            title={participant.displayName || "User"}
                          >
                            <Avatar
                              src={
                                participant.photoURL || "/api/placeholder/24/24"
                              }
                              alt={participant.displayName || "User"}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "bill_updated":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={activity.initiatorPhoto || "/api/placeholder/32/32"}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.initiatorName || "Someone"} updated a bill
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.3),
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="500">
                        {activity.billDescription || "Unnamed bill"}
                      </Typography>
                      <Chip
                        label={activity.billSplitType || "equal"}
                        size="small"
                        sx={{
                          bgcolor: (theme) =>
                            alpha(theme.palette.warning.main, 0.1),
                          color: "warning.main",
                          height: 24,
                        }}
                      />
                    </Box>

                    {/* Display what was changed */}
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="caption" color="text.secondary">
                        Changes made:
                      </Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        {activity.changes?.description && (
                          <Chip
                            size="small"
                            label="Description"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                        {activity.changes?.amount && (
                          <Chip
                            size="small"
                            label="Amount"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                        {activity.changes?.paidBy && (
                          <Chip
                            size="small"
                            label="Payer"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                        {activity.changes?.splitType && (
                          <Chip
                            size="small"
                            label="Split Type"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                        {activity.changes?.participants && (
                          <Chip
                            size="small"
                            label="Participants"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                        {activity.changes?.items && (
                          <Chip
                            size="small"
                            label="Items"
                            variant="outlined"
                            sx={{
                              bgcolor: (theme) =>
                                alpha(theme.palette.warning.main, 0.1),
                            }}
                          />
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        New Amount:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary.main"
                        fontWeight="600"
                      >
                        {formatCurrency(activity.billAmount)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "bill_deleted":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {/* Header with initiator info */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={
                        activity.initiator?.photoURL || "/api/placeholder/32/32"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.initiator?.displayName || "Someone"} deleted a
                      bill
                    </Typography>
                  </Box>

                  {/* Deleted Bill details */}
                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.3),
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="500">
                        {activity.bill?.description || "Unnamed bill"}
                      </Typography>
                      <Chip
                        label={activity.bill?.splitType || "equal"}
                        size="small"
                        sx={{
                          bgcolor: (theme) =>
                            alpha(theme.palette.error.main, 0.1),
                          color: "error.main",
                          height: 24,
                        }}
                      />
                    </Box>

                    {/* Display bill amount */}
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        Total Amount:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="error.main"
                        fontWeight="600"
                      >
                        {formatCurrency(activity.bill?.amount || 0)}
                      </Typography>
                    </Box>

                    {/* Display participants if available */}
                    {activity.bill?.participants &&
                      activity.bill.participants.length > 0 && (
                        <Box sx={{ mt: 2, display: "inline-block" }}>
                          <AvatarGroup
                            max={4}
                            sx={{
                              mt: 1,
                              "& .MuiAvatar-root": {
                                width: 24,
                                height: 24,
                                fontSize: "0.75rem",
                                border: "2px solid",
                                borderColor: "background.paper",
                              },
                            }}
                          >
                            {activity.bill.participants.map((participant) => (
                              <Tooltip
                                key={participant.userId}
                                title={participant.displayName || "User"}
                              >
                                <Avatar
                                  src={
                                    participant.photoURL ||
                                    "/api/placeholder/24/24"
                                  }
                                  alt={participant.displayName || "User"}
                                />
                              </Tooltip>
                            ))}
                          </AvatarGroup>
                        </Box>
                      )}

                    {/* Paid by info */}
                    {activity.bill?.paidBy && (
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          opacity: 0.7,
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          Originally paid by:
                        </Typography>
                        <Avatar
                          src={
                            activity.bill.participants?.find(
                              (p) => p.userId === activity.bill.paidBy
                            )?.photoURL || "/api/placeholder/16/16"
                          }
                          sx={{ width: 16, height: 16 }}
                        />
                        <Typography variant="caption" color="text.secondary">
                          {activity.bill.participants?.find(
                            (p) => p.userId === activity.bill.paidBy
                          )?.displayName || "User"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "group_created":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {/* Header with creator info */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={
                        activity.creator?.photoURL || "/api/placeholder/32/32"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.creator?.displayName || "Someone"} created this
                      group
                    </Typography>
                  </Box>

                  {/* Group details */}
                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.3),
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body1" fontWeight="500">
                      {activity.groupDetails?.name || "New Group"}
                    </Typography>

                    {/* Initial Members */}
                    <Box sx={{ mt: 2, display: "inline-block" }}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          mt: 1,
                          "& .MuiAvatar-root": {
                            width: 24,
                            height: 24,
                            fontSize: "0.75rem",
                            border: "2px solid",
                            borderColor: "background.paper",
                          },
                        }}
                      >
                        {activity.groupDetails?.members?.map((member) => (
                          <Tooltip
                            key={member.userId}
                            title={member.displayName || "User"}
                          >
                            <Avatar
                              src={member.photoURL || "/api/placeholder/24/24"}
                              alt={member.displayName || "User"}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>

                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ display: "block", mt: 1 }}
                    >
                      {activity.groupDetails?.memberCount || 0} member
                      {activity.groupDetails?.memberCount !== 1 ? "s" : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "group_updated":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {/* Header with initiator info */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={
                        activity.initiator?.photoURL || "/api/placeholder/32/32"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.initiator?.displayName || "Someone"} updated the
                      group
                    </Typography>
                  </Box>

                  {/* Changes details */}
                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.3),
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    {activity.changes?.name && (
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          Group name changed from:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: 0.5,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              textDecoration: "line-through",
                              color: "text.secondary",
                            }}
                          >
                            {activity.changes.name.previous}
                          </Typography>
                          <ArrowForwardIcon
                            sx={{ fontSize: 16, color: "text.secondary" }}
                          />
                          <Typography
                            variant="body2"
                            color="primary.main"
                            fontWeight="500"
                          >
                            {activity.changes.name.current}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {/* Additional changes can be added here */}
                    {Object.keys(activity.changes || {}).length === 0 && (
                      <Typography variant="body2" color="text.secondary">
                        General group settings were updated
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "member_added":
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                src={activity.member?.photoURL || "/api/placeholder/32/32"}
              />
              <Box>
                <Typography variant="body1">
                  {activity.member?.displayName || "Someone"} joined the group
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Added by {activity.initiatorName || "someone"}
                </Typography>
              </Box>
            </Box>
          );
        case "member_removed":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {/* Header with initiator info */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Avatar
                      src={
                        activity.initiator?.photoURL || "/api/placeholder/32/32"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {activity.initiator?.displayName || "Someone"} removed{" "}
                      {activity.member?.displayName || "a member"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
                      p: 2,
                      borderRadius: 1,
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Avatar
                      src={
                        activity.member?.photoURL || "/api/placeholder/32/32"
                      }
                      sx={{ width: 32, height: 32 }}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight={500}>
                        {activity.member?.displayName || activity.member?.email}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {activity.member?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );

        case "settlement_completed":
          return (
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Settlement completed for{" "}
                    {activity.billDescription || "a bill"}
                  </Typography>

                  <Box
                    sx={{
                      bgcolor: (theme) =>
                        alpha(theme.palette.success.main, 0.1),
                      p: 2,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={
                            activity.from?.photoURL || "/api/placeholder/32/32"
                          }
                          sx={{ width: 32, height: 32 }}
                        />
                        <Typography variant="body2">
                          {activity.from?.displayName || "Someone"}
                        </Typography>
                      </Box>

                      <ArrowForwardIcon sx={{ color: "text.secondary" }} />

                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={
                            activity.to?.photoURL || "/api/placeholder/32/32"
                          }
                          sx={{ width: 32, height: 32 }}
                        />
                        <Typography variant="body2">
                          {activity.to?.displayName || "Someone"}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography
                      variant="body2"
                      color="success.main"
                      fontWeight={600}
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.success.main, 0.1),
                        px: 2,
                        py: 1,
                        borderRadius: 1,
                      }}
                    >
                      {formatCurrency(activity.amount)} paid
                    </Typography>
                  </Box>

                  {activity.settlementDetails && (
                    <Box
                      sx={{
                        mt: 1,
                        p: 1,
                        bgcolor: (theme) =>
                          alpha(theme.palette.background.paper, 0.3),
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        Original Bill Amount:{" "}
                        {formatCurrency(
                          activity.settlementDetails.originalAmount
                        )}{" "}
                        • Split Type: {activity.settlementDetails.splitType}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );

        default:
          return null;
      }
    };

    return (
      <ActivityItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              bgcolor: (theme) => getActivityColor(theme, activity.type),
            }}
          >
            {getActivityIcon(activity.type)}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <ActivityContent>
            {formatActivityContent()}
            <TimeStamp>
              {activity.timestamp?.toDate?.()?.toLocaleString() ||
                new Date().toLocaleString()}
            </TimeStamp>
          </ActivityContent>
        </TimelineContent>
      </ActivityItem>
    );
  };

  const renderMobileActivity = (activity) => {
    if (!activity.type) return null;

    const commonCardProps = {
      key: activity.id,
      sx: { mb: 2 },
    };

    switch (activity.type) {
      case "bill_created":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
              <Avatar
                src={activity.initiatorPhoto || "/api/placeholder/32/32"}
                sx={{ width: 40, height: 40 }}
              />
              <Typography variant="body2" sx={{ mb: 1 }}>
                {activity.initiatorName || "Someone"} added a new bill
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.3),
                p: 2,
                borderRadius: 1,
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1.5,
                }}
              >
                <Typography variant="subtitle2">
                  {activity.billDescription}
                </Typography>
                <Chip
                  label={activity.billSplitType}
                  size="small"
                  sx={{
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                    color: "primary.main",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  Total Amount
                </Typography>
                <Typography variant="subtitle2" color="primary.main">
                  {formatCurrency(activity.billAmount)}
                </Typography>
              </Box>
            </Box>

            <AvatarGroup
              max={4}
              sx={{
                "& .MuiAvatar-root": {
                  width: 24,
                  height: 24,
                  fontSize: "0.75rem",
                  border: "2px solid",
                  borderColor: "background.paper",
                },
              }}
            >
              {activity.billParticipants?.map((participant) => (
                <Tooltip
                  key={participant.userId}
                  title={participant.displayName || "User"}
                >
                  <Avatar
                    src={participant.photoURL || "/api/placeholder/24/24"}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>

            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block", mt: 1 }}
            >
              {activity.timestamp?.toDate().toLocaleString()}
            </Typography>
          </MobileActivityCard>
        );
      case "bill_updated":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
              <Avatar
                src={activity.initiatorPhoto || "/api/placeholder/32/32"}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body2">
                {activity.initiatorName || "Someone"} updated a bill
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.3),
                p: 2,
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography variant="body1" fontWeight="500">
                  {activity.billDescription || "Unnamed bill"}
                </Typography>
                <Chip
                  label={activity.billSplitType || "equal"}
                  size="small"
                  sx={{
                    bgcolor: (theme) => alpha(theme.palette.warning.main, 0.1),
                    color: "warning.main",
                    height: 24,
                  }}
                />
              </Box>

              {/* Display what was changed */}
              <Box sx={{ mt: 2 }}>
                <Typography variant="caption" color="text.secondary">
                  Changes made:
                </Typography>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  {activity.changes?.description && (
                    <Chip
                      size="small"
                      label="Description"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                  {activity.changes?.amount && (
                    <Chip
                      size="small"
                      label="Amount"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                  {activity.changes?.paidBy && (
                    <Chip
                      size="small"
                      label="Payer"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                  {activity.changes?.splitType && (
                    <Chip
                      size="small"
                      label="Split Type"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                  {activity.changes?.participants && (
                    <Chip
                      size="small"
                      label="Participants"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                  {activity.changes?.items && (
                    <Chip
                      size="small"
                      label="Items"
                      variant="outlined"
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.main, 0.1),
                      }}
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  New Amount:
                </Typography>
                <Typography
                  variant="body2"
                  color="primary.main"
                  fontWeight="600"
                >
                  {formatCurrency(activity.billAmount)}
                </Typography>
              </Box>
            </Box>
          </MobileActivityCard>
        );
      case "bill_deleted":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              {/* Header with initiator info */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                }}
              >
                <Avatar
                  src={activity.initiator?.photoURL || "/api/placeholder/32/32"}
                  sx={{ width: 24, height: 24 }}
                />
                <Typography variant="body2">
                  {activity.initiator?.displayName || "Someone"} deleted a bill
                </Typography>
              </Box>

              {/* Deleted Bill details */}
              <Box
                sx={{
                  bgcolor: (theme) =>
                    alpha(theme.palette.background.paper, 0.3),
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body1" fontWeight="500">
                    {activity.bill?.description || "Unnamed bill"}
                  </Typography>
                  <Chip
                    label={activity.bill?.splitType || "equal"}
                    size="small"
                    sx={{
                      bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
                      color: "error.main",
                      height: 24,
                    }}
                  />
                </Box>

                {/* Display bill amount */}
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="caption" color="text.secondary">
                    Total Amount:
                  </Typography>
                  <Typography
                    variant="body2"
                    color="error.main"
                    fontWeight="600"
                  >
                    {formatCurrency(activity.bill?.amount || 0)}
                  </Typography>
                </Box>

                {/* Display participants if available */}
                {activity.bill?.participants &&
                  activity.bill.participants.length > 0 && (
                    <Box sx={{ mt: 2, display: "inline-block" }}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          mt: 1,
                          "& .MuiAvatar-root": {
                            width: 24,
                            height: 24,
                            fontSize: "0.75rem",
                            border: "2px solid",
                            borderColor: "background.paper",
                          },
                        }}
                      >
                        {activity.bill.participants.map((participant) => (
                          <Tooltip
                            key={participant.userId}
                            title={participant.displayName || "User"}
                          >
                            <Avatar
                              src={
                                participant.photoURL || "/api/placeholder/24/24"
                              }
                              alt={participant.displayName || "User"}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>
                  )}

                {/* Paid by info */}
                {activity.bill?.paidBy && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      opacity: 0.7,
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      Originally paid by:
                    </Typography>
                    <Avatar
                      src={
                        activity.bill.participants?.find(
                          (p) => p.userId === activity.bill.paidBy
                        )?.photoURL || "/api/placeholder/16/16"
                      }
                      sx={{ width: 16, height: 16 }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {activity.bill.participants?.find(
                        (p) => p.userId === activity.bill.paidBy
                      )?.displayName || "User"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </MobileActivityCard>
        );
      case "settlement_completed":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Typography variant="subtitle2" gutterBottom>
              Settlement completed for {activity.billDescription}
            </Typography>

            <Box
              sx={{
                bgcolor: (theme) => alpha(theme.palette.success.main, 0.1),
                p: 2,
                borderRadius: 1,
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar
                    src={activity.from?.photoURL || "/api/placeholder/32/32"}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography variant="body2">
                    {activity.from?.displayName}
                  </Typography>
                </Box>
                <ArrowForwardIcon sx={{ color: "text.secondary" }} />
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar
                    src={activity.to?.photoURL || "/api/placeholder/32/32"}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography variant="body2">
                    {activity.to?.displayName}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="subtitle2"
                color="success.main"
                align="center"
              >
                {formatCurrency(activity.amount)} paid
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1.5,
                p: 1.5,
                bgcolor: alpha(theme.palette.background.paper, 0.3),
                borderRadius: 1,
              }}
            >
              <Typography
                variant="caption"
                display="block"
                color="text.secondary"
              >
                Original Bill Amount:{" "}
                {formatCurrency(activity.settlementDetails?.originalAmount)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {activity.timestamp?.toDate().toLocaleString()}
              </Typography>
            </Box>
          </MobileActivityCard>
        );
      case "group_created":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
              <Avatar
                src={activity.creator?.photoURL || "/api/placeholder/32/32"}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body2">
                {activity.creator?.displayName || "Someone"} created this group
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.3),
                p: 2,
                borderRadius: 1,
              }}
            >
              <Typography variant="body1" fontWeight="500">
                {activity.groupDetails?.name || "New Group"}
              </Typography>

              {/* Initial Members */}
              <Box sx={{ mt: 2, display: "inline-block" }}>
                <AvatarGroup
                  max={4}
                  sx={{
                    mt: 1,
                    "& .MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      fontSize: "0.75rem",
                      border: "2px solid",
                      borderColor: "background.paper",
                    },
                  }}
                >
                  {activity.groupDetails?.members?.map((member) => (
                    <Tooltip
                      key={member.userId}
                      title={member.displayName || "User"}
                    >
                      <Avatar
                        src={member.photoURL || "/api/placeholder/24/24"}
                        alt={member.displayName || "User"}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Box>

              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: "block", mt: 1 }}
              >
                {activity.groupDetails?.memberCount || 0} member
                {activity.groupDetails?.memberCount !== 1 ? "s" : ""}
              </Typography>
            </Box>
          </MobileActivityCard>
        );
      case "group_updated":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <Avatar
                src={activity.initiator?.photoURL || "/api/placeholder/32/32"}
                sx={{ width: 40, height: 40 }}
              />
              <Typography variant="body2">
                {activity.initiator?.displayName} updated the group
              </Typography>
            </Box>

            {activity.changes?.name && (
              <Box
                sx={{
                  p: 2,
                  bgcolor: (theme) =>
                    alpha(theme.palette.background.paper, 0.3),
                  borderRadius: 1,
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  Group name changed from:
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: "line-through",
                      color: "text.secondary",
                    }}
                  >
                    {activity.changes.name.previous}
                  </Typography>
                  <ArrowForwardIcon
                    sx={{ fontSize: 16, color: "text.secondary" }}
                  />
                  <Typography variant="body2" color="primary.main">
                    {activity.changes.name.current}
                  </Typography>
                </Box>
              </Box>
            )}

            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 1.5, display: "block" }}
            >
              {activity.timestamp?.toDate().toLocaleString()}
            </Typography>
          </MobileActivityCard>
        );
      case "member_added":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <Avatar
                src={activity.member?.photoURL || "/api/placeholder/32/32"}
              />
              <Typography variant="body1">
                {activity.member?.displayName || "Someone"} joined the group
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                Added by {activity.initiatorName || "someone"}
              </Typography>
            </Box>
          </MobileActivityCard>
        );
      case "member_removed":
        return (
          <MobileActivityCard {...commonCardProps}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <Avatar
                src={activity.initiator?.photoURL || "/api/placeholder/32/32"}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body2">
                {activity.initiator?.displayName || "Someone"} removed{" "}
                {activity.member?.displayName || "a member"}
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
                p: 2,
                borderRadius: 1,
                mt: 1,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={activity.member?.photoURL || "/api/placeholder/32/32"}
                sx={{ width: 32, height: 32 }}
              />
              <Box>
                <Typography variant="body2" fontWeight={500}>
                  {activity.member?.displayName || activity.member?.email}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {activity.member?.email}
                </Typography>
              </Box>
            </Box>
          </MobileActivityCard>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Timeline>
          {activities.map((activity, index) => (
            <ActivityCard key={activity?.id || index} activity={activity} />
          ))}
        </Timeline>
      </Box>
      {/* Mobile List View */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : activities.length === 0 ? (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Typography color="text.secondary">No activity to show</Typography>
          </Box>
        ) : (
          activities.map((activity) => renderMobileActivity(activity))
        )}
      </Box>
    </>
  );
};

export default GroupActivityTimeline;
