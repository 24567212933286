import React, { useEffect } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  alpha,
  styled,
  InputLabel,
  Box,
  Typography
} from '@mui/material';
import { BILL_CATEGORIES, suggestCategory } from '../utils/billCategories';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& svg': {
    fontSize: 20,
  }
}));

const CategorySelect = ({ value, onChange, description, disabled = false }) => {
  useEffect(() => {
    if (description && !value) {
      const suggestedCategory = suggestCategory(description);
      onChange({ target: { value: suggestedCategory } });
    }
  }, [description, value, onChange]);

  return (
    <StyledFormControl fullWidth>
      <InputLabel>Category</InputLabel>
      <Select
        value={value || ''}
        onChange={onChange}
        label="Category"
        disabled={disabled}
      >
        {BILL_CATEGORIES.map((category) => {
          const Icon = category.icon;
          return (
            <MenuItem key={category.id} value={category.id}>
              <CategoryIcon>
                <Icon color="primary" />
                <Typography>{category.label}</Typography>
              </CategoryIcon>
            </MenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
};

export default CategorySelect;