import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Button,
  Avatar,
  alpha,
  styled,
  CircularProgress,
  AvatarGroup,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  ArrowForward as ArrowForwardIcon,
  CheckCircle as CheckCircleIcon,
  AccountBalance as AccountBalanceIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";

const BreakdownCard = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.6), // Increased opacity
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.background.paper, 0.7), // Even lighter for mobile
  },
}));

const UserBalanceRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  marginBottom: theme.spacing(1),
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateX(4px)",
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
  },
}));

const UserHeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    gap: theme.spacing(1),
  },
}));

const MobileBalanceHeader = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: alpha(theme.palette.background.paper, 0.2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const TransactionCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.4), // Increased opacity
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
  "&:hover": {
    transform: "translateX(4px)",
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.background.paper, 0.6), // Lighter background for mobile
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`, // Added border for definition
  },
}));

const SettleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius * 1.5,
  fontWeight: 600,
  transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
    transform: "translateY(-2px)",
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    color: alpha(theme.palette.common.black, 0.5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const BillsList = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
  borderRadius: theme.shape.borderRadius * 1.5,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
  },
}));

const BillItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    transform: "translateX(4px)",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
  },
}));

const BillDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}));

const TransactionUserInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  flex: 1,
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.background.default, 0.2),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
    backgroundColor: alpha(theme.palette.background.default, 0.3), // Slightly lighter background
  },
}));

const TransactionAmount = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: alpha(theme.palette.background.paper, 0.2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const UserAvatarPair = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  "& .MuiAvatar-root": {
    width: 48,
    height: 48,
    border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
    opacity: 0.7,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
    marginBottom: theme.spacing(1), // Add spacing below the avatars
  },
}));

const BalanceDetails = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const BalanceAmount = styled(Typography)(({ theme }) => ({
  textAlign: "right",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
    backgroundColor: alpha(theme.palette.background.paper, 0.2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  },
}));

const DetailedSettlementsView = ({
  data,
  loading,
  onSettle,
  settlingTransaction,
}) => {
  const theme = useTheme();
  useEffect(() => {
    console.log("Settlement View Data:", data);
  }, [data]);
  const { user } = useAuth();
  const { detailedBalances, totalAmount, simplifiedTransactions } = data || {};

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
    }).format(Math.abs(amount));
  };

  const renderBillsList = (bills) => {
    if (!bills?.length) return null;

    return (
      <BillsList>
        {bills.slice(0, 3).map((bill, index) => (
          <BillItem key={index}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              <ReceiptIcon fontSize="small" color="action" />
              <BillDetails>
                <Typography
                  variant="caption"
                  sx={{
                    flex: 1,
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.8rem",
                      fontWeight: 500,
                    },
                  }}
                >
                  {bill.description}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "primary.main",
                    },
                  }}
                >
                  {formatCurrency(bill.amount)}
                </Typography>
              </BillDetails>
            </Box>
          </BillItem>
        ))}
        {bills.length > 3 && (
          <Typography
            variant="caption"
            color="text.secondary"
            align="center"
            sx={{
              display: "block",
              mt: 1,
              [theme.breakpoints.down("sm")]: {
                backgroundColor: alpha(theme.palette.background.paper, 0.2),
                p: 1,
                borderRadius: 1,
              },
            }}
          >
            +{bills.length - 3} more bills
          </Typography>
        )}
      </BillsList>
    );
  };
  const renderTransactionCard = (transaction, index) => (
    <TransactionCard key={index}>
      <TransactionUserInfo>
        <UserAvatarPair>
          <Tooltip title={transaction.fromUser.displayName}>
            <Avatar
              src={transaction.fromUser.photoURL || "/api/placeholder/48/48"}
              alt={transaction.fromUser.displayName}
            />
          </Tooltip>
          <ArrowForwardIcon fontSize="large" />
          <Tooltip title={transaction.toUser.displayName}>
            <Avatar
              src={transaction.toUser.photoURL || "/api/placeholder/48/48"}
              alt={transaction.toUser.displayName}
            />
          </Tooltip>
        </UserAvatarPair>
        <Box
          sx={{
            flex: 1,
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              width: "100%",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 0.5,
              [theme.breakpoints.down("sm")]: {
                fontSize: "1rem",
                color: theme.palette.common.white, // Ensure text is visible
                fontWeight: 500,
              },
            }}
          >
            {transaction.fromUser.displayName} →{" "}
            {transaction.toUser.displayName}
          </Typography>

          <Typography
            variant="h5"
            color="primary"
            sx={{
              fontWeight: 600,
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.25rem",
                textAlign: "center",
                color: theme.palette.primary.main, // Ensure amount is visible
              },
            }}
          >
            {formatCurrency(transaction.amount)}
          </Typography>
        </Box>
      </TransactionUserInfo>

      {(transaction.from === user?.uid || transaction.to === user?.uid) && (
        <SettleButton
          variant="contained"
          onClick={() => onSettle(transaction)}
          disabled={
            settlingTransaction?.id === `${transaction.from}-${transaction.to}`
          }
          startIcon={
            settlingTransaction?.id ===
            `${transaction.from}-${transaction.to}` ? (
              <CircularProgress size={20} />
            ) : (
              <CheckCircleIcon />
            )
          }
        >
          {settlingTransaction?.id === `${transaction.from}-${transaction.to}`
            ? "Processing..."
            : "Settle Up"}
        </SettleButton>
      )}
    </TransactionCard>
  );
  const renderUserBalanceRow = (userData) => (
    <UserBalanceRow key={userData.user.userId}>
      <UserHeaderRow>
        {/* User Avatar and Basic Info Section */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: { xs: "100%", sm: "auto" },
            alignItems: "center",
          }}
        >
          <Avatar
            src={userData.user.photoURL || "/api/placeholder/40/40"}
            sx={{ width: 40, height: 40 }}
          />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">
              {userData.user.displayName}
              {userData.user.userId === user?.uid && (
                <Typography
                  component="span"
                  variant="caption"
                  color="primary"
                  sx={{ ml: 1 }}
                >
                  (You)
                </Typography>
              )}
            </Typography>

            {/* Mobile-only payment details */}
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                mt: 0.5,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                Paid: {formatCurrency(userData.totalPaid)}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Desktop payment details */}
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            flex: 1,
          }}
        >
          <Typography variant="caption" color="text.secondary">
            Paid: {formatCurrency(userData.totalPaid)} • Owes:{" "}
            {formatCurrency(userData.totalOwed)}
          </Typography>
        </Box>

        {/* Balance Amount - Desktop */}
        <BalanceAmount
          variant="subtitle1"
          color={userData.balance > 0 ? "success.main" : "error.main"}
          sx={{
            fontWeight: 500,
            display: { xs: "none", sm: "block" },
          }}
        >
          {userData.balance > 0 ? "Gets back" : "Needs to pay"}{" "}
          {formatCurrency(Math.abs(userData.balance))}
        </BalanceAmount>

        {/* Mobile Balance Display */}
        <MobileBalanceHeader>
          <Typography variant="caption" color="text.secondary">
            Owes: {formatCurrency(userData.totalOwed)}
          </Typography>
          <Typography
            variant="subtitle2"
            color={userData.balance > 0 ? "success.main" : "error.main"}
            sx={{ fontWeight: 500 }}
          >
            {formatCurrency(Math.abs(userData.balance))}
          </Typography>
        </MobileBalanceHeader>
      </UserHeaderRow>

      {/* Bills list */}
      {renderBillsList(userData.bills)}
    </UserBalanceRow>
  );

  return (
    <Box>
      {/* Balance Summary */}
      <BreakdownCard>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <AccountBalanceIcon color="primary" />
          <Typography variant="h6">Balance Summary</Typography>
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Total Group Expenses: {formatCurrency(totalAmount || 0)}
        </Typography>

        {/* Map through user balances using the renderUserBalanceRow function */}
        {Object.values(detailedBalances || {}).map((userData) =>
          renderUserBalanceRow(userData)
        )}
      </BreakdownCard>

      {/* Simplified Transactions */}
      {simplifiedTransactions?.transactions?.length > 0 && (
        <BreakdownCard>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <AccountBalanceIcon color="primary" />
            <Typography variant="h6">Settlement Plan</Typography>
          </Box>

          {/* Map through transactions using the renderTransactionCard function */}
          {simplifiedTransactions.transactions.map((transaction, index) =>
            renderTransactionCard(transaction, index)
          )}
        </BreakdownCard>
      )}
    </Box>
  );
};

export default DetailedSettlementsView;
