import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import LoginScreen from "./components/LoginScreen";
import ResponsiveNav from "./components/ResponsiveNav";
import MainContainer from "./components/MainContainer";
import {
  Box,
  CircularProgress,
  ThemeProvider,
  createTheme,
  CssBaseline,
  alpha,
} from "@mui/material";
import DashboardPage from "./components/DashboardPage";
import SettingsPage from "./components/SettingsPage";
import AddBill from "./components/AddBill";
import TransactionHistory from "./components/TransactionHistory";
import GroupsList from "./components/GroupsList";
import CreateGroup from "./components/CreateGroup";
import GroupDetails from "./components/GroupDetails";
import OnboardingPage from "./components/OnboardingPage";
import InvitesManagement from "./components/invite/InvitesManagement";
import InviteHandler from "./components/invite/InviteHandler";
import EditBill from "./components/EditBill";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#181A1F",
      paper: "#222429",
    },
    primary: {
      main: "#F5DB54",
    },
    secondary: {
      main: "#9968FF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      letterSpacing: "-0.02em",
    },
    h6: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.05)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
        },
      },
    },
  },
});

const LoadingScreen = () => (
  <Box
    sx={{
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: `linear-gradient(135deg, ${alpha(
        "#181A1F",
        0.95
      )} 0%, ${alpha("#181A1F", 0.98)} 100%)`,
    }}
  >
    <CircularProgress sx={{ color: theme.palette.primary.main }} />
  </Box>
);

const RequireAuth = ({ children }) => {
  const { user, userData, loading, validateToken } = useAuth();
  const location = useLocation();
  const [validatingAuth, setValidatingAuth] = useState(true);

  useEffect(() => {
    const validateAuthentication = async () => {
      try {
        if (user) {
          const isValid = await validateToken();
          if (!isValid) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.error('Auth validation error:', error);
      } finally {
        setValidatingAuth(false);
      }
    };

    validateAuthentication();
  }, [user, validateToken]);

  if (loading || validatingAuth) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!userData?.isOnboarded && location.pathname !== "/onboarding") {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  return children;
};

const PublicInviteRoute = ({ children }) => {
  const { user, loading, validateToken } = useAuth();
  const { inviteId } = useParams();
  const location = useLocation();
  const [validatingAuth, setValidatingAuth] = useState(true);

  useEffect(() => {
    if (inviteId) {
      sessionStorage.setItem("pendingInvite", inviteId);
    }

    const validateAuthentication = async () => {
      try {
        if (user) {
          const isValid = await validateToken();
          if (!isValid) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.error('Auth validation error:', error);
      } finally {
        setValidatingAuth(false);
      }
    };

    validateAuthentication();
  }, [inviteId, user, validateToken]);

  if (loading || validatingAuth) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location, inviteId }} replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const { user, userData, loading, validateToken } = useAuth();
  const [validatingAuth, setValidatingAuth] = useState(true);

  useEffect(() => {
    const validateAuthentication = async () => {
      try {
        if (user) {
          const isValid = await validateToken();
          if (!isValid) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.error('Auth validation error:', error);
      } finally {
        setValidatingAuth(false);
      }
    };

    validateAuthentication();
  }, [user, validateToken]);

  if (loading || validatingAuth) {
    return <LoadingScreen />;
  }

  if (user) {
    if (!userData?.isOnboarded) {
      return <Navigate to="/onboarding" replace />;
    }
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

const AuthenticatedLayout = ({ children }) => {
  return (
    <>
      <ResponsiveNav />
      <MainContainer>{children}</MainContainer>
    </>
  );
};

const AppRouter = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginScreen />
              </PublicRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <RequireAuth>
                <OnboardingPage />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <DashboardPage />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/invites"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <InvitesManagement />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/invite/:inviteId"
            element={
              <PublicInviteRoute>
                <InviteHandler />
              </PublicInviteRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <SettingsPage />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/transactions"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <TransactionHistory />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <GroupsList />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups/add-bill"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <AddBill isAutoGroup={true} />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups/create"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <CreateGroup />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups/:groupId"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <GroupDetails />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups/:groupId/add-bill"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <AddBill />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/groups/:groupId/bills/:billId/edit"
            element={
              <RequireAuth>
                <AuthenticatedLayout>
                  <EditBill />
                </AuthenticatedLayout>
              </RequireAuth>
            }
          />

          {/* Redirect root to dashboard */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />

          {/* Catch all other routes and redirect to dashboard */}
          <Route
            path="*"
            element={
              <RequireAuth>
                <Navigate to="/dashboard" replace />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
