export const SUPPORTED_CURRENCIES = {
  USD: { symbol: "$", name: "US Dollar", locale: "en-US", numberingSystem: "latn" },
  EUR: { symbol: "€", name: "Euro", locale: "en-US", numberingSystem: "latn" },
  GBP: { symbol: "£", name: "British Pound", locale: "en-US", numberingSystem: "latn" },
  AUD: { symbol: "A$", name: "Australian Dollar", locale: "en-US", numberingSystem: "latn" },
  CAD: { symbol: "C$", name: "Canadian Dollar", locale: "en-US", numberingSystem: "latn" },
  INR: { symbol: "₹", name: "Indian Rupee", locale: "en-US", numberingSystem: "latn" },
  AED: { symbol: "AED", name: "UAE Dirham", locale: "en-US", numberingSystem: "latn" },
  BHD: { symbol: "BHD", name: "Bahraini Dinar", locale: "en-US", numberingSystem: "latn" },
  KWD: { symbol: "KWD", name: "Kuwaiti Dinar", locale: "en-US", numberingSystem: "latn" },
};

export const formatCurrency = (amount, currencyCode = "USD", options = {}) => {
  if (typeof amount !== "number" || isNaN(amount)) {
    amount = 0;
  }

  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      numberingSystem: "latn", // Force Latin numerals
      minimumFractionDigits: options.minimumFractionDigits ?? 2,
      maximumFractionDigits: options.maximumFractionDigits ?? 2,
      ...options,
    }).format(amount);
  } catch (error) {
    console.error("Currency formatting error:", error);
    return `${currencyCode} ${amount.toFixed(2)}`;
  }
};

/**
 * Gets the currency symbol for a given currency code
 * @param {string} currencyCode - The ISO currency code
 * @returns {string} Currency symbol
 */
export const getCurrencySymbol = (currencyCode = "USD") => {
  return SUPPORTED_CURRENCIES[currencyCode]?.symbol || "$";
};

/**
 * Validates if a given currency code is supported
 * @param {string} currencyCode - The ISO currency code to validate
 * @returns {boolean} Whether the currency is supported
 */
export const isValidCurrency = (currencyCode) => {
  return Object.keys(SUPPORTED_CURRENCIES).includes(currencyCode);
};

/**
 * Gets user's currency based on their locale, defaulting to USD if not supported
 * @returns {string} The ISO currency code
 */
export const getDefaultCurrencyByLocale = () => {
  try {
    const locale = navigator.language;
    const currency = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "USD",
    }).resolvedOptions().currency;

    return isValidCurrency(currency) ? currency : "USD";
  } catch {
    return "USD";
  }
};
