import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
  setDoc,
  runTransaction
} from "firebase/firestore";
import { db } from "../../backend-services/firebase";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  alpha,
  styled,
  Paper,
  Button,
  Fade,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Group as GroupIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const LoadingContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(135deg, ${alpha("#181A1F", 0.95)} 0%, ${alpha(
    "#181A1F",
    0.98
  )} 100%)`,
  padding: theme.spacing(3),
}));

const StatusCard = styled(Paper)(({ theme, status }) => ({
  padding: theme.spacing(4),
  maxWidth: 500,
  width: "100%",
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  textAlign: "center",
}));

const IconWrapper = styled(Box)(({ theme, status }) => ({
  width: 80,
  height: 80,
  borderRadius: "50%",
  backgroundColor: alpha(
    status === "success"
      ? theme.palette.success.main
      : status === "error"
      ? theme.palette.error.main
      : theme.palette.primary.main,
    0.1
  ),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  marginBottom: theme.spacing(2),
  "& svg": {
    fontSize: 40,
    color:
      status === "success"
        ? theme.palette.success.main
        : status === "error"
        ? theme.palette.error.main
        : theme.palette.primary.main,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
}));

const ProgressStage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: alpha(theme.palette.common.white, 0.7),
}));

const InviteHandler = () => {
  const { inviteId } = useParams();
  const { user, refreshUserData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [processingStage, setProcessingStage] = useState("Verifying invite...");
  const [inviteData, setInviteData] = useState(null);
  const [processingComplete, setProcessingComplete] = useState(false);
  const [processingStep, setProcessingStep] = useState("Verifying invite...");

  useEffect(() => {
    const handleInvite = async () => {
      if (!user?.uid || !inviteId) return;

      try {
        await runTransaction(db, async (transaction) => {
          const inviteRef = doc(db, "invites", inviteId);
          const inviteDoc = await transaction.get(inviteRef);

          if (!inviteDoc.exists()) {
            throw new Error("Invite not found");
          }

          const invite = inviteDoc.data();
          if (invite.to.email.toLowerCase() !== user.email.toLowerCase()) {
            throw new Error("This invite was sent to a different email address");
          }

          if (invite.type === "group" && invite.groupId) {
            const groupRef = doc(db, "groups", invite.groupId);
            const groupDoc = await transaction.get(groupRef);

            if (!groupDoc.exists()) {
              throw new Error("Group not found");
            }

            const groupData = groupDoc.data();
            if (groupData.memberUserIds?.includes(user.uid)) {
              return { redirect: true, groupId: invite.groupId };
            }

            const memberData = {
              userId: user.uid,
              displayName: user.displayName || "",
              email: user.email,
              photoURL: user.photoURL || "",
              role: "member",
              addedAt: new Date(),
              status: "active",
              inviteId: inviteId
            };

            const updatedGroup = {
              members: [...(groupData.members || []), memberData],
              memberUserIds: [...(groupData.memberUserIds || []), user.uid],
              updatedAt: serverTimestamp()
            };

            if (invite.status === "pending") {
              transaction.update(inviteRef, {
                status: "accepted",
                acceptedAt: serverTimestamp(),
                "to.userId": user.uid,
                "to.displayName": user.displayName || "",
                "to.photoURL": user.photoURL || ""
              });
            }
            transaction.update(groupRef, updatedGroup);

            const userGroupRef = doc(db, `users/${user.uid}/groups/${invite.groupId}`);
            transaction.set(userGroupRef, {
              groupId: invite.groupId,
              joinedAt: serverTimestamp(),
              role: "member",
              status: "active",
              name: groupData.name
            });

            return { redirect: true, groupId: invite.groupId };
          }
        });

        await refreshUserData();
        
        navigate(`/groups/${inviteId}`);

      } catch (error) {
        console.error("Error handling invite:", error);
        setError(error.message || "Failed to process invite");
        setLoading(false);
      }
    };

    handleInvite();
  }, [user?.uid, inviteId, navigate, refreshUserData]);

  const handleNavigation = () => {
    if (success && inviteData?.type === "group" && inviteData?.groupId) {
      navigate(`/groups/${inviteData.groupId}`);
    } else {
      navigate("/dashboard");
    }
  };

  if (!user || !inviteId) {
    return null;
  }

  return (
    <LoadingContainer>
      <Fade in={true} timeout={800}>
        <StatusCard status={error ? "error" : success ? "success" : "loading"}>
          {loading ? (
            <>
              <CircularProgress size={60} sx={{ mb: 3 }} />
              <Typography variant="h5" color="primary" gutterBottom>
                Processing Your Invite
              </Typography>
              <ProgressStage variant="body1">{processingStage}</ProgressStage>
            </>
          ) : error ? (
            <>
              <IconWrapper status="error">
                <ErrorIcon />
              </IconWrapper>
              <Typography variant="h5" gutterBottom>
                Oops! Something went wrong
              </Typography>
              <Alert
                severity="error"
                sx={{
                  mt: 2,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.error.main, 0.1),
                }}
              >
                {error}
              </Alert>
              <ActionButton onClick={() => navigate("/dashboard")}>
                Go to Dashboard
              </ActionButton>
            </>
          ) : success ? (
            <>
              <IconWrapper status="success">
                {inviteData?.type === "group" ? (
                  <GroupIcon />
                ) : (
                  <CheckCircleIcon />
                )}
              </IconWrapper>
              <Typography variant="h5" color="primary" gutterBottom>
                {inviteData?.type === "group"
                  ? `Welcome to ${
                      inviteData?.metadata?.groupName || "the group"
                    }!`
                  : "Invite Accepted Successfully"}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {inviteData?.type === "group"
                  ? "You're now a member of the group. You can start splitting bills and managing expenses together."
                  : "You can now start splitting bills with your friends."}
              </Typography>
              <ActionButton
                onClick={handleNavigation}
                endIcon={<ArrowForwardIcon />}
              >
                {inviteData?.type === "group"
                  ? "Go to Group"
                  : "Go to Dashboard"}
              </ActionButton>
            </>
          ) : null}
        </StatusCard>
      </Fade>
    </LoadingContainer>
  );
};

export default InviteHandler;
