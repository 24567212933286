import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  auth,
  createOrUpdateUser,
  initializePresence,
  db,
} from "../backend-services/firebase";
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  getTokenFromStorage,
  removeTokenFromStorage,
  setTokenInStorage,
} from "../utils/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(getTokenFromStorage());
  const [isInitialAuthCheck, setIsInitialAuthCheck] = useState(true);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [loadingInvites, setLoadingInvites] = useState(true);
  const [pendingInviteId, setPendingInviteId] = useState(
    sessionStorage.getItem("pendingInvite")
  );

  const refreshToken = async (user) => {
    try {
      const newToken = await user.getIdToken(true);
      localStorage.setItem("firebaseToken", newToken);
      setToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  const refreshUserData = async () => {
    if (!user?.uid) return null;

    try {
      console.log("Refreshing user data...");
      const firestoreData = await createOrUpdateUser({
        ...user,
        lastActive: new Date(),
      });
      console.log("Updated user data:", firestoreData);
      setUserData(firestoreData);
      return firestoreData;
    } catch (error) {
      console.error("Error refreshing user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    let tokenRefreshInterval;

    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      try {
        if (currentUser) {
          const idToken = await currentUser.getIdToken();
          localStorage.setItem("firebaseToken", idToken);
          setToken(idToken);
          setUser(currentUser);

          tokenRefreshInterval = setInterval(() => {
            refreshToken(currentUser);
          }, 45 * 60 * 1000);

          const firestoreData = await createOrUpdateUser({
            ...currentUser,
            lastLoginAt: new Date(),
            lastActive: new Date(),
          });
          setUserData(firestoreData);

          await initializePresence(currentUser.uid);
        } else {
          setUser(null);
          setUserData(null);
          setToken(null);
          setPendingInvites([]);
          localStorage.removeItem("firebaseToken");
        }
      } catch (error) {
        console.error("Error in auth state change:", error);
      } finally {
        setLoading(false);
        setIsInitialAuthCheck(false);
      }
    });

    return () => {
      unsubscribe();
      if (tokenRefreshInterval) {
        clearInterval(tokenRefreshInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          console.log("User data updated:", data);
          setUserData(data);

          if (isInitialAuthCheck && !data.isOnboarded) {
            setUserData((prev) => ({
              ...prev,
              isNewUser: true,
            }));
          }
        }
        setIsInitialAuthCheck(false);
      },
      (error) => {
        console.error("Error listening to user data:", error);
        setIsInitialAuthCheck(false);
      }
    );

    return () => unsubscribe();
  }, [user?.uid, isInitialAuthCheck]);

  useEffect(() => {
    if (!user?.uid) {
      setPendingInvites([]);
      setLoadingInvites(false);
      return;
    }

    const invitesQuery = query(
      collection(db, "invites"),
      where("to.userId", "==", user.uid),
      where("status", "==", "pending"),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(
      invitesQuery,
      (snapshot) => {
        const invites = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPendingInvites(invites);
        setLoadingInvites(false);
      },
      (error) => {
        console.error("Error subscribing to invites:", error);
        setLoadingInvites(false);
      }
    );

    return () => unsubscribe();
  }, [user?.uid]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const idToken = await user.getIdToken();
          setToken(idToken);
          setTokenInStorage(idToken);

          if (pendingInviteId) {
            try {
              await handleInvitedUser(user, pendingInviteId);
              sessionStorage.removeItem("pendingInvite");
              setPendingInviteId(null);
            } catch (inviteError) {
              console.error("Error handling invite:", inviteError);
              const firestoreData = await createOrUpdateUser({
                ...user,
                lastLoginAt: new Date(),
                lastActive: new Date(),
              });
              setUserData(firestoreData);
            }
          } else {
            const firestoreData = await createOrUpdateUser({
              ...user,
              lastLoginAt: new Date(),
              lastActive: new Date(),
            });
            setUserData(firestoreData);
          }
          await initializePresence(user.uid);
        } catch (error) {
          console.error("Error syncing user data with Firestore:", error);
        }
      } else {
        setUserData(null);
        setToken(null);
        setPendingInvites([]);
        removeTokenFromStorage();
      }
      setLoading(false);
    });

    const tokenRefreshInterval = setInterval(async () => {
      if (auth.currentUser) {
        try {
          const newToken = await auth.currentUser.getIdToken(true);
          setToken(newToken);
          setTokenInStorage(newToken);
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }, 45 * 60 * 1000);

    return () => {
      unsubscribe();
      clearInterval(tokenRefreshInterval);
    };
  }, [pendingInviteId]);

  const handleInvitedUser = async (user, inviteId) => {
    try {
      const inviteRef = doc(db, "invites", inviteId);
      const inviteSnap = await getDoc(inviteRef);

      if (!inviteSnap.exists()) {
        throw new Error("Invite not found");
      }

      const inviteData = inviteSnap.data();

      if (inviteData.to.email.toLowerCase() !== user.email.toLowerCase()) {
        throw new Error("Email mismatch");
      }

      const firestoreData = await createOrUpdateUser({
        ...user,
        lastLoginAt: new Date(),
        lastActive: new Date(),
        inviteContext: {
          inviteId,
          invitedBy: inviteData.from.userId,
          inviteType: inviteData.type,
          invitedAt: new Date(),
        },
        isOnboarded: true,
      });

      setUserData(firestoreData);
      await initializePresence(user.uid);

      return firestoreData;
    } catch (error) {
      console.error("Error handling invited user:", error);
      throw error;
    }
  };

  const validateToken = useCallback(async () => {
    if (user) {
      try {
        const currentToken = await user.getIdToken();
        const storedToken = localStorage.getItem("firebaseToken");

        if (currentToken !== storedToken) {
          await refreshToken(user);
        }
        return true;
      } catch (error) {
        console.error("Token validation failed:", error);
        return false;
      }
    }
    return false;
  }, [user]);

  const value = {
    user,
    loading: loading || loadingInvites,
    userData,
    setToken,
    refreshToken,
    validateToken,
    token,
    refreshUserData,
    isInitialAuthCheck,
    pendingInvites,
    loadingInvites,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
