import {
    Restaurant as FoodIcon,
    HomeWork as RentIcon,
    LocalGroceryStore as GroceriesIcon,
    DirectionsCar as TransportIcon,
    LocalHospital as HealthcareIcon,
    School as EducationIcon,
    SportsEsports as EntertainmentIcon,
    ShoppingBag as ShoppingIcon,
    Build as UtilitiesIcon,
    CreditCard as OthersIcon,
    AccountBalance as BillsIcon,
    Tv as StreamingIcon,
    SelfImprovement as WellnessIcon,
    WorkspacePremium as WorkToolsIcon,
    LocalLibrary as ReadingIcon,
    Hearing as MusicIcon,
    Gamepad as GamepadIcon,
    FitnessCenter as FitnessIcon,
    LocalLaundryService as HouseholdIcon,
    Palette as CreativeToolsIcon,
    Person as PersonalCareIcon,
    Pets as PetsIcon,
    ChildCare as ChildcareIcon,
    AttachMoney as InvestmentIcon,
    Security as InsuranceIcon,
    Celebration as EventsIcon,
    SportsBasketball as SportsIcon,
    Language as LanguageIcon,
    Computer as TechnologyIcon,
  } from '@mui/icons-material';
  
  export const BILL_CATEGORIES = [
    // Essential Living
    {
      id: 'rent',
      label: 'Rent & Housing',
      icon: RentIcon,
      keywords: ['rent', 'house', 'apartment', 'housing', 'mortgage', 'accommodation', 'lease'],
    },
    {
      id: 'utilities',
      label: 'Utilities',
      icon: UtilitiesIcon,
      keywords: ['utilities', 'electricity', 'water', 'gas', 'internet', 'phone', 'broadband', 'wifi'],
    },
    {
      id: 'groceries',
      label: 'Groceries',
      icon: GroceriesIcon,
      keywords: ['groceries', 'supermarket', 'food', 'supplies', 'household', 'market', 'fresh'],
    },
  
    // Food & Dining
    {
      id: 'food',
      label: 'Food & Dining',
      icon: FoodIcon,
      keywords: ['restaurant', 'food', 'dining', 'lunch', 'dinner', 'breakfast', 'cafe', 'meal', 'takeout', 'delivery', 'zomato', 'swiggy', 'uber eats'],
    },
  
    // Transportation
    {
      id: 'transport',
      label: 'Transport',
      icon: TransportIcon,
      keywords: ['transport', 'uber', 'lyft', 'ola', 'taxi', 'bus', 'train', 'fuel', 'gas', 'metro', 'subway', 'car', 'bike'],
    },
  
    // Digital Subscriptions
    {
      id: 'streaming',
      label: 'Streaming Services',
      icon: StreamingIcon,
      keywords: ['netflix', 'amazon prime', 'hotstar', 'disney+', 'hulu', 'hbo max', 'apple tv+', 'sonyliv', 'zee5', 'voot', 'youtube premium', 'peacock', 'paramount+'],
    },
    {
      id: 'music',
      label: 'Music Services',
      icon: MusicIcon,
      keywords: ['spotify', 'apple music', 'youtube music', 'amazon music', 'gaana', 'jiosaavn', 'tidal', 'pandora'],
    },
    {
      id: 'gaming',
      label: 'Gaming Services',
      icon: GamepadIcon,
      keywords: ['playstation plus', 'xbox game pass', 'nintendo online', 'ea play', 'steam', 'epic games', 'ubisoft+', 'cloud gaming'],
    },
  
    // Work & Productivity
    {
      id: 'work_tools',
      label: 'Work & Productivity',
      icon: WorkToolsIcon,
      keywords: ['microsoft 365', 'office', 'google workspace', 'slack', 'zoom', 'dropbox', 'evernote', 'notion', 'asana', 'trello'],
    },
    {
      id: 'ai_tools',
      label: 'AI & Assistant Tools',
      icon: TechnologyIcon,
      keywords: ['chatgpt', 'claude', 'copilot', 'bard', 'midjourney', 'dalle', 'ai', 'assistant', 'jasper', 'grammarly'],
    },
  
    // Health & Wellness
    {
      id: 'healthcare',
      label: 'Healthcare',
      icon: HealthcareIcon,
      keywords: ['health', 'medical', 'doctor', 'hospital', 'medicine', 'pharmacy', 'clinic', 'dental', 'therapy'],
    },
    {
      id: 'wellness',
      label: 'Mental Wellness',
      icon: WellnessIcon,
      keywords: ['headspace', 'calm', 'meditation', 'therapy', 'betterhelp', 'talkspace', 'mindfulness', 'yoga'],
    },
    {
      id: 'fitness',
      label: 'Fitness',
      icon: FitnessIcon,
      keywords: ['gym', 'fitness', 'workout', 'peloton', 'nike training', 'strava', 'fitbod', 'myfitnesspal'],
    },
  
    // Education & Learning
    {
      id: 'education',
      label: 'Education',
      icon: EducationIcon,
      keywords: ['education', 'school', 'university', 'college', 'course', 'books', 'tuition', 'udemy', 'coursera', 'skillshare', 'masterclass'],
    },
    {
      id: 'reading',
      label: 'Reading & News',
      icon: ReadingIcon,
      keywords: ['kindle unlimited', 'audible', 'scribd', 'medium', 'substack', 'newspaper', 'magazine', 'news subscription'],
    },
    {
      id: 'language',
      label: 'Language Learning',
      icon: LanguageIcon,
      keywords: ['duolingo', 'babbel', 'rosetta stone', 'busuu', 'memrise', 'language', 'learning'],
    },
  
    // Entertainment & Hobbies
    {
      id: 'entertainment',
      label: 'Entertainment',
      icon: EntertainmentIcon,
      keywords: ['entertainment', 'movie', 'game', 'party', 'fun', 'activities', 'shows', 'concerts', 'events'],
    },
    {
      id: 'creative_tools',
      label: 'Creative Tools',
      icon: CreativeToolsIcon,
      keywords: ['adobe creative cloud', 'canva', 'figma', 'procreate', 'lightroom', 'photoshop', 'premier pro'],
    },
    {
      id: 'sports',
      label: 'Sports & Recreation',
      icon: SportsIcon,
      keywords: ['sports', 'gym', 'fitness', 'equipment', 'classes', 'coaching', 'training'],
    },
  
    // Shopping & Personal
    {
      id: 'shopping',
      label: 'Shopping',
      icon: ShoppingIcon,
      keywords: ['shopping', 'clothes', 'accessories', 'retail', 'amazon', 'online shopping'],
    },
    {
      id: 'personal_care',
      label: 'Personal Care',
      icon: PersonalCareIcon,
      keywords: ['salon', 'spa', 'grooming', 'haircut', 'beauty', 'skincare', 'cosmetics'],
    },
    {
      id: 'household',
      label: 'Household & Maintenance',
      icon: HouseholdIcon,
      keywords: ['cleaning', 'maintenance', 'repair', 'furniture', 'appliances', 'decor', 'supplies'],
    },
  
    // Family & Care
    {
      id: 'pets',
      label: 'Pet Care',
      icon: PetsIcon,
      keywords: ['pet', 'dog', 'cat', 'veterinary', 'pet food', 'grooming', 'pet supplies'],
    },
    {
      id: 'childcare',
      label: 'Childcare & Education',
      icon: ChildcareIcon,
      keywords: ['childcare', 'babysitting', 'daycare', 'school fees', 'tuition', 'kids activities'],
    },
  
    // Financial Services
    {
      id: 'investment',
      label: 'Investment Services',
      icon: InvestmentIcon,
      keywords: ['investment', 'trading', 'broker', 'mutual funds', 'stocks', 'robinhood', 'etoro'],
    },
    {
      id: 'insurance',
      label: 'Insurance',
      icon: InsuranceIcon,
      keywords: ['insurance', 'health insurance', 'car insurance', 'life insurance', 'home insurance'],
    },
  
    // Special Categories
    {
      id: 'events',
      label: 'Events & Celebrations',
      icon: EventsIcon,
      keywords: ['event', 'party', 'celebration', 'wedding', 'birthday', 'anniversary', 'holiday'],
    },
    {
      id: 'regular_bills',
      label: 'Regular Bills',
      icon: BillsIcon,
      keywords: ['bills', 'subscription', 'monthly', 'recurring', 'payment', 'installment'],
    },
    {
      id: 'others',
      label: 'Others',
      icon: OthersIcon,
      keywords: ['others', 'miscellaneous', 'other'],
    },
  ];
  
  export const suggestCategory = (description) => {
    if (!description) return 'others';
    
    const normalizedDescription = description.toLowerCase();
    
    // First try exact matches
    for (const category of BILL_CATEGORIES) {
      if (category.keywords.some(keyword => 
        normalizedDescription === keyword || 
        normalizedDescription.startsWith(keyword + ' ') ||
        normalizedDescription.endsWith(' ' + keyword)
      )) {
        return category.id;
      }
    }
  
    // Then try partial matches
    for (const category of BILL_CATEGORIES) {
      if (category.keywords.some(keyword => normalizedDescription.includes(keyword))) {
        return category.id;
      }
    }
    
    return 'others';
  };
  
  // Function to get category details by ID
  export const getCategoryById = (categoryId) => {
    return BILL_CATEGORIES.find(category => category.id === categoryId) || BILL_CATEGORIES.find(category => category.id === 'others');
  };