// In GroupSimplifiedDebts.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Alert,
  CircularProgress,
  alpha,
  styled,
  Button,
  Paper,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import {
  getSimplifiedGroupDebts,
  handleSettlementComplete,
} from "../backend-services/firebase";
import DetailedSettlementsView from "./DetailedSettlementsView";

const PageSection = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  minHeight: 200,
}));

const ErrorContainer = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiAlert-action": {
    alignItems: "center",
  },
}));

const GroupSimplifiedDebts = ({ groupId, onUpdate }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [settlingTransaction, setSettlingTransaction] = useState(null);
  const { user } = useAuth();

  const loadDebts = async () => {
    if (!groupId || !user?.uid) return;

    try {
      setLoading(true);
      setError(null);
      console.log("Loading debts for group:", groupId);
      const debts = await getSimplifiedGroupDebts(groupId, user.uid);
      console.log("Loaded debts:", debts);
      setData(debts);
    } catch (err) {
      console.error("Error loading debts:", err);
      setError(err.message || "Failed to load settlement data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDebts();
  }, [groupId, user?.uid]);

  const handleSettle = async (transaction) => {
    if (!transaction || !groupId) return;

    try {
      setSettlingTransaction({
        ...transaction,
        id: `${transaction.from}-${transaction.to}`,
      });

      // Create a mock bill structure for settlement
      const mockBill = {
        id: `settlement-${groupId}-${Date.now()}`,
        groupId,
        description: `Settlement payment`,
        amount: transaction.amount,
        participants: [
          {
            userId: transaction.from,
            displayName: transaction.fromUser.displayName,
            email: transaction.fromUser.email,
            photoURL: transaction.fromUser.photoURL,
          },
          {
            userId: transaction.to,
            displayName: transaction.toUser.displayName,
            email: transaction.toUser.email,
            photoURL: transaction.toUser.photoURL,
          },
        ],
      };

      await handleSettlementComplete(mockBill, {
        from: transaction.from,
        to: transaction.to,
        amount: transaction.amount,
      });

      // Refresh the data
      await loadDebts();
      if (onUpdate) {
        onUpdate();
      }
    } catch (err) {
      console.error("Error settling transaction:", err);
      setError(
        "Failed to process settlement: " + (err.message || "Unknown error")
      );
    } finally {
      setSettlingTransaction(null);
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={40} />
        <Typography variant="body2" color="text.secondary">
          Calculating settlements...
        </Typography>
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={loadDebts}>
            Retry
          </Button>
        }
      >
        {error}
      </ErrorContainer>
    );
  }

  if (!data || !data.simplifiedTransactions?.transactions?.length) {
    return (
      <PageSection>
        <Typography variant="h6" align="center" gutterBottom>
          No Settlements Required
        </Typography>
        <Typography color="text.secondary" align="center">
          All expenses are settled in this group
        </Typography>
      </PageSection>
    );
  }

  return (
    <DetailedSettlementsView
      data={data}
      loading={loading}
      onSettle={handleSettle}
      settlingTransaction={settlingTransaction}
    />
  );
};

export default GroupSimplifiedDebts;
