import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Avatar,
  Box,
  Typography,
  CircularProgress,
  styled,
  alpha,
  Chip,
} from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";
import { searchUsers } from "../backend-services/firebase";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.6),
    },
    "&.Mui-focused": {
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
    },
  },
}));

const EmailChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.warning.main, 0.1),
  color: theme.palette.warning.main,
  '& .MuiChip-icon': {
    color: theme.palette.warning.main,
  },
}));

const UserSearch = ({
  onChange,
  excludeUsers = [],
  placeholder = "Search users or enter email...",
  multiple = false,
  value = null,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = (email) => emailRegex.test(email);

  const createEmailOption = (email) => ({
    userId: `invite_${email.toLowerCase()}`,
    email: email.toLowerCase(),
    displayName: email,
    isInvited: true
  });

  useEffect(() => {
    const fetchUsers = async () => {
      if (!inputValue || inputValue.length < 2) {
        setOptions([]);
        return;
      }

      setLoading(true);
      try {
        // If it's a valid email, add it as an option
        if (isValidEmail(inputValue)) {
          const emailOption = createEmailOption(inputValue);
          const searchResults = await searchUsers(inputValue, excludeUsers.map(u => u.userId));
          setOptions([emailOption, ...searchResults]);
        } else {
          const results = await searchUsers(inputValue, excludeUsers.map(u => u.userId));
          setOptions(results);
        }
      } catch (error) {
        console.error('Search error:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [inputValue, excludeUsers]);

  return (
    <StyledAutocomplete
      multiple={multiple}
      options={options}
      value={value}
      onChange={onChange}
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      getOptionLabel={(option) => option.email || option.displayName || ""}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.isInvited ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <EmailIcon color="warning" />
              <Typography color="warning.main">
                Invite: {option.email}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar src={option.photoURL || "/api/placeholder/32/32"} />
              <Box>
                <Typography>{option.displayName}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.email}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          option.isInvited ? (
            <EmailChip
              {...getTagProps({ index })}
              key={option.userId}
              label={option.email}
              icon={<EmailIcon />}
            />
          ) : (
            <Chip
              {...getTagProps({ index })}
              key={option.userId}
              avatar={<Avatar src={option.photoURL || "/api/placeholder/32/32"} />}
              label={option.displayName || option.email}
            />
          )
        ))
      }
    />
  );
};

export default UserSearch;