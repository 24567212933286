import React from "react";
import {
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  alpha,
  styled,
} from "@mui/material";

const PreviewCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const GroupPreview = ({ group }) => {
  if (!group) return null;

  return (
    <PreviewCard>
      <Typography variant="subtitle2" gutterBottom>
        Selected Group
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h6">{group.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {group.members.length} members
          </Typography>
        </Box>
        <AvatarGroup max={4}>
          {group.members.map((member) => (
            <Avatar
              key={member.userId}
              src={member.photoURL || "/api/placeholder/32/32"}
              alt={member.displayName}
            />
          ))}
        </AvatarGroup>
      </Box>
    </PreviewCard>
  );
};

export default GroupPreview;
