import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  createBill,
  createGroup,
  logBillCreated,
} from "../backend-services/firebase";

const generateGroupName = (participants) => {
  const names = participants
    .map((p) => p.displayName || p.email?.split("@")[0])
    .slice(0, 3);

  if (names.length <= 2) {
    return `${names.join(" & ")}'s Group`;
  }

  return `${names[0]}, ${names[1]} & ${names.length - 2} other${
    names.length > 3 ? "s" : ""
  }`;
};

export const useBillManagement = () => {
  const { user } = useAuth();
  const [isCreatingBill, setIsCreatingBill] = useState(false);
  const [billError, setBillError] = useState(null);
  const [validationMessages, setValidationMessages] = useState({
    name: "",
    amount: "",
    participants: "",
    paidBy: "",
    percentage: "",
    shares: "",
    items: "",
  });
  const [billData, setBillData] = useState(() => {
    const currentUserParticipant = user
      ? {
          userId: user.uid,
          displayName: user.displayName || "",
          email: user.email || "",
          photoURL: user.photoURL || "",
        }
      : null;

    return {
      name: "",
      description: "",
      amount: "",
      splitType: "equal",
      category: 'others',
      participants: currentUserParticipant ? [currentUserParticipant] : [],
      paidBy: currentUserParticipant ? currentUserParticipant.userId : "",
      shares: {},
      items: [],
      groupId: null,
      category: "others"
    };
  });

  const [participantShares, setParticipantShares] = useState({});

  const calculateShares = (type, amount, participants, shares, paidBy) => {
    const totalAmount = parseFloat(amount);
    if (isNaN(totalAmount) || totalAmount <= 0) {
      return {};
    }

    const calculatedShares = {};

    switch (type) {
      case "equal": {
        const perPersonShare = parseFloat(
          (totalAmount / participants.length).toFixed(2)
        );
        participants.forEach((p) => {
          calculatedShares[p.userId] = {
            original: perPersonShare,
            settlement:
              p.userId === paidBy
                ? perPersonShare - totalAmount
                : perPersonShare,
          };
        });
        break;
      }

      case "percentage": {
        const totalPercentage = participants.reduce(
          (sum, p) =>
            sum + (parseFloat(participantShares[p.userId]?.percentage) || 0),
          0
        );

        if (Math.abs(totalPercentage - 100) > 0.01) {
          return {};
        }

        let totalCalculatedAmount = 0;

        // Calculate initial shares
        participants.forEach((p) => {
          const percentage = parseFloat(
            participantShares[p.userId]?.percentage || 0
          );
          const share = parseFloat(
            ((totalAmount * percentage) / 100).toFixed(2)
          );
          totalCalculatedAmount += share;

          calculatedShares[p.userId] = {
            original: share,
            settlement: p.userId === paidBy ? share - totalAmount : share,
          };
        });

        // Handle any rounding discrepancy
        const discrepancy = totalAmount - totalCalculatedAmount;
        if (Math.abs(discrepancy) > 0.01) {
          // Add the discrepancy to the participant with the highest percentage
          const highestPercentageParticipant = participants.reduce(
            (prev, curr) => {
              const prevPercentage = parseFloat(
                participantShares[prev.userId]?.percentage || 0
              );
              const currPercentage = parseFloat(
                participantShares[curr.userId]?.percentage || 0
              );
              return prevPercentage > currPercentage ? prev : curr;
            }
          );

          calculatedShares[highestPercentageParticipant.userId].original +=
            discrepancy;
          if (highestPercentageParticipant.userId === paidBy) {
            calculatedShares[highestPercentageParticipant.userId].settlement +=
              discrepancy;
          }
        }
        break;
      }

      case "shares": {
        const totalShares = participants.reduce(
          (sum, p) => sum + (parseFloat(shares[p.userId]?.shares) || 1),
          0
        );

        if (totalShares <= 0) return {};

        participants.forEach((p) => {
          const userShares = parseFloat(shares[p.userId]?.shares || 1);
          const share = parseFloat(
            ((totalAmount * userShares) / totalShares).toFixed(2)
          );

          calculatedShares[p.userId] = {
            original: share,
            settlement: p.userId === paidBy ? share - totalAmount : share,
          };
        });
        break;
      }

      case "items": {
        return calculateItemBasedShares(billData.items, participants, paidBy);
      }

      default:
        return {};
    }

    // Validate total shares
    const totalCalculated = Object.values(calculatedShares).reduce(
      (sum, share) => sum + share.original,
      0
    );

    if (Math.abs(totalCalculated - totalAmount) > 0.01) {
      // Adjust for rounding errors
      const maxShare = Object.values(calculatedShares).reduce(
        (max, share) => Math.max(max, share.original),
        0
      );
      const maxShareUserId = Object.entries(calculatedShares).find(
        ([_, share]) => share.original === maxShare
      )?.[0];

      if (maxShareUserId) {
        const adjustment = totalAmount - totalCalculated;
        calculatedShares[maxShareUserId].original += adjustment;
        if (maxShareUserId === paidBy) {
          calculatedShares[maxShareUserId].settlement += adjustment;
        }
      }
    }

    return calculatedShares;
  };

  const calculateItemBasedShares = (items, participants, paidBy) => {
    const shares = {};
    const totalAmount = items.reduce(
      (sum, item) => sum + parseFloat(item.totalAmount || 0),
      0
    );

    participants.forEach((p) => {
      shares[p.userId] = {
        original: 0,
        settlement: 0,
      };
    });

    items.forEach((item) => {
      if (!item.participants?.length) return;

      const perPersonShare = parseFloat(
        (item.totalAmount / item.participants.length).toFixed(2)
      );

      item.participants.forEach((participantId) => {
        if (shares[participantId]) {
          shares[participantId].original += perPersonShare;
        }
      });
    });

    Object.keys(shares).forEach((userId) => {
      shares[userId].settlement =
        userId === paidBy
          ? shares[userId].original - totalAmount
          : shares[userId].original;
    });

    return shares;
  };

  const handleSplitTypeChange = (newSplitType) => {
    setBillData((prev) => ({
      ...prev,
      splitType: newSplitType,
      items: newSplitType === "items" ? prev.items : [],
      amount: newSplitType === "items" ? calculateTotalAmount() : prev.amount,
    }));

    if (billData.participants.length > 0) {
      const newShares = {};

      switch (newSplitType) {
        case "equal":
          setParticipantShares({});
          break;

        case "percentage": {
          const equalPercentage = (100 / billData.participants.length).toFixed(
            1
          );
          billData.participants.forEach((p) => {
            newShares[p.userId] = {
              percentage: parseFloat(equalPercentage),
            };
          });
          setParticipantShares(newShares);
          break;
        }

        case "shares": {
          billData.participants.forEach((p) => {
            newShares[p.userId] = {
              shares: 1,
            };
          });
          setParticipantShares(newShares);
          break;
        }

        case "items":
          setParticipantShares({});
          break;

        default:
          break;
      }

      if (billData.amount || newSplitType === "items") {
        recalculateShares(newSplitType);
      }
    }
  };

  const handleBillDataChange = (field) => (event) => {
    const value = event.target.value;
    setBillData((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (field === "amount" || field === "paidBy") {
      recalculateShares();
    }
  };

  const handleParticipantChange = (event, newValue) => {
    const currentUserParticipant = billData.participants.find(
      (p) => p.userId === user.uid
    );
    let updatedParticipants = newValue;

    if (
      !newValue.some((p) => p.userId === user.uid) &&
      currentUserParticipant
    ) {
      updatedParticipants = [...newValue, currentUserParticipant];
    }
    setBillData((prev) => ({
      ...prev,
      participants: updatedParticipants,
      paidBy: updatedParticipants.find((p) => p.userId === prev.paidBy)
        ? prev.paidBy
        : user.uid,
      items: prev.items.map((item) => ({
        ...item,
        participants: item.participants.filter((pId) =>
          updatedParticipants.some((p) => p.userId === pId)
        ),
      })),
    }));
  };

  const handleSplitValueChange = (participantId, value) => {
    const isPercentage = billData.splitType === "percentage";
    const numericValue = isPercentage
      ? Math.min(100, Math.max(0, parseFloat(value) || 0))
      : Math.max(1, parseInt(value) || 1);

    setParticipantShares((prev) => {
      const newShares = {
        ...prev,
        [participantId]: {
          [isPercentage ? "percentage" : "shares"]: numericValue,
        },
      };

      // Trigger share recalculation immediately
      const updatedShares = calculateShares(
        billData.splitType,
        billData.amount,
        billData.participants,
        newShares,
        billData.paidBy
      );

      // Update bill data with new shares
      setBillData((prevBillData) => ({
        ...prevBillData,
        shares: updatedShares,
      }));

      return newShares;
    });
  };

  const handleItemAdd = (newItem) => {
    setBillData((prev) => {
      const updatedItems = [
        ...(prev.items || []),
        {
          ...newItem,
          id: Date.now().toString(),
          participants: newItem.participants || [],
          perPersonShare: parseFloat(
            (newItem.totalAmount / newItem.participants.length).toFixed(2)
          ),
          pricePerItem: parseFloat(
            (newItem.totalAmount / newItem.quantity).toFixed(2)
          ),
        },
      ];

      const updatedShares = calculateItemBasedShares(
        updatedItems,
        prev.participants,
        prev.paidBy
      );

      const newTotalAmount = updatedItems
        .reduce((sum, item) => sum + parseFloat(item.totalAmount || 0), 0)
        .toString();

      return {
        ...prev,
        items: updatedItems,
        shares: updatedShares,
        amount: newTotalAmount,
      };
    });
  };

  const handleItemRemove = (itemId) => {
    setBillData((prev) => {
      const updatedItems = prev.items.filter((item) => item.id !== itemId);

      const updatedShares = calculateItemBasedShares(
        updatedItems,
        prev.participants,
        prev.paidBy
      );

      const newTotalAmount = updatedItems
        .reduce((sum, item) => sum + parseFloat(item.totalAmount || 0), 0)
        .toString();

      return {
        ...prev,
        items: updatedItems,
        shares: updatedShares,
        amount: newTotalAmount,
      };
    });
  };

  const calculateTotalAmount = () => {
    if (billData.splitType === "items") {
      return billData.items
        .reduce((sum, item) => sum + (parseFloat(item.totalAmount) || 0), 0)
        .toFixed(2);
    }
    return parseFloat(billData.amount) || 0;
  };

  const recalculateShares = (splitType = billData.splitType) => {
    if (!billData.participants.length || !billData.paidBy) {
      return;
    }

    const amount =
      splitType === "items" ? calculateTotalAmount() : billData.amount;

    console.log("Bill Data::", billData, amount);

    if (!amount) return;

    if (splitType === "percentage") {
      const total = Object.values(participantShares).reduce(
        (sum, share) => sum + (parseFloat(share?.percentage) || 0),
        0
      );
      if (Math.abs(total - 100) > 0.01) return;
    }

    const calculatedShares = calculateShares(
      splitType,
      amount,
      billData.participants,
      participantShares,
      billData.paidBy
    );

    setBillData((prev) => ({
      ...prev,
      shares: calculatedShares,
    }));
  };

  const validateSplitValues = () => {
    switch (billData.splitType) {
      case "equal":
        return true;

      case "percentage": {
        const total = Object.values(participantShares).reduce(
          (sum, share) => sum + (parseFloat(share?.percentage) || 0),
          0
        );
        return Math.abs(total - 100) < 0.01;
      }

      case "shares": {
        if (!billData.paidBy) return false;
        return billData.participants.every((p) => {
          const share = parseFloat(participantShares[p.userId]?.shares);
          return share && share > 0;
        });
      }

      case "items":
        if (!billData.items.length) return false;

        return billData.items.every((item) => {
          if (!item.name || !item.quantity || !item.totalAmount) return false;

          if (parseInt(item.quantity) <= 0) return false;
          if (parseFloat(item.totalAmount) <= 0) return false;
          if (!item.participants?.length) return false;

          return item.participants.every((participantId) =>
            billData.participants.some((p) => p.userId === participantId)
          );
        });

      default:
        return false;
    }
  };

  const validateBillData = () => {
    const newMessages = {};

    if (!billData.name.trim()) {
      newMessages.name = "Bill name is required";
    }

    if (billData.participants.length < 2) {
      newMessages.participants = "At least 2 participants are required";
    }

    if (!billData.paidBy) {
      newMessages.paidBy = "Please select who paid the bill";
    }

    if (billData.splitType !== "items") {
      const amount = parseFloat(billData.amount);
      if (!amount || amount <= 0) {
        newMessages.amount = "Please enter a valid amount";
      }
    }

    switch (billData.splitType) {
      case "percentage": {
        const totalPercentage = Object.values(participantShares).reduce(
          (sum, share) => sum + (parseFloat(share?.percentage) || 0),
          0
        );

        if (Math.abs(totalPercentage - 100) > 0.01) {
          newMessages.percentage = `Total percentage must be 100% (current: ${totalPercentage.toFixed(
            1
          )}%)`;
        }
        break;
      }

      case "shares": {
        const hasInvalidShares = billData.participants.some((p) => {
          const share = parseFloat(participantShares[p.userId]?.shares);
          return !share || share <= 0;
        });

        if (hasInvalidShares) {
          newMessages.shares = "All participants must have valid share values";
        }
        break;
      }

      case "items": {
        if (!billData.items.length) {
          newMessages.items = "Please add at least one item";
        } else {
          const invalidItems = billData.items.filter((item) => {
            return (
              !item.name.trim() ||
              !item.quantity ||
              parseInt(item.quantity) <= 0 ||
              !item.totalAmount ||
              parseFloat(item.totalAmount) <= 0 ||
              !item.participants?.length
            );
          });

          if (invalidItems.length > 0) {
            newMessages.items =
              "All items must have valid name, quantity, amount and participants";
          }
        }
        break;
      }
    }

    setValidationMessages(newMessages);
    return Object.keys(newMessages).length === 0;
  };

  const createSingleBill = async (groupId = null) => {
    if (!validateBillData()) {
      setBillError("Please correct the errors");
      return { success: false };
    }

    try {
      setIsCreatingBill(true);
      setBillError(null);

      let finalGroupId = billData.groupId || groupId;

      if (!finalGroupId && billData.participants.length > 1) {
        const groupName = generateGroupName(billData.participants);
        const groupData = {
          name: groupName,
          createdBy: user.uid,
          members: billData.participants,
        };
        finalGroupId = await createGroup(groupData);
      }

      const calculatedShares = calculateShares(
        billData.splitType,
        billData.amount,
        billData.participants,
        participantShares,
        billData.paidBy
      );

      const participantsWithShares = billData.participants.map(
        (participant) => ({
          ...participant,
          share: {
            original: calculatedShares[participant.userId].original,
            settlement: calculatedShares[participant.userId].settlement,
          },
        })
      );

      const result = await createBill({
        description: billData.name,
        amount: parseFloat(billData.amount),
        paidBy: billData.paidBy,
        splitType: billData.splitType,
        category: billData.category,
        participants: participantsWithShares,
        createdBy: user.uid,
        items: billData.items || [],
        splitDetails: participantShares,
        groupId: finalGroupId,
        billType: finalGroupId ? "group" : "personal",
      });
      if (finalGroupId) {
        try {
          await logBillCreated(finalGroupId, {
            bill: {
              id: result,
              description: billData.name,
              amount: parseFloat(billData.amount),
              splitType: billData.splitType,
              category: billData.category,
              participants: participantsWithShares,
              splitDetails: participantShares,
              items: billData.items || [],
            },
            initiator: {
              userId: user.uid,
              displayName: user.displayName || '',
              photoURL: user.photoURL
            }
          });
        } catch (error) {
          console.error("Error logging bill activity:", error);
        }
      }
      return { success: true, groupId: finalGroupId };
    } catch (error) {
      console.error("Error creating bill:", error);
      setBillError(error.message);
      return { success: false, error };
    } finally {
      setIsCreatingBill(false);
    }
  };

  const updateBillData = (newData) => {
    setBillData((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  useEffect(() => {
    recalculateShares();
  }, [
    billData.amount,
    billData.participants.length,
    billData.paidBy,
    billData.splitType,
    participantShares,
  ]);

  return {
    billData,
    billError,
    isCreatingBill,
    setIsCreatingBill,
    participantShares,
    handleBillDataChange,
    handleParticipantChange,
    handleSplitValueChange,
    handleItemAdd,
    handleItemRemove,
    createSingleBill,
    canSubmitBill: validateSplitValues(),
    setBillError,
    handleSplitTypeChange,
    calculateTotalAmount,
    validateSplitValues,
    validationMessages,
    validateBillData,
    setValidationMessages,
    billError,
    setBillError,
    setIsCreatingBill,
    setBillData,
    updateBillData,
    recalculateShares,
  };
};

export default useBillManagement;
