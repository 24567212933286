import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  alpha,
  styled,
  useTheme,
  Container,
  useMediaQuery,
  Badge,
  Typography,
  Popover,
  Tooltip,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  History as HistoryIcon,
  Group,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../backend-services/firebase";
import InviteList from "./invite/InviteListView";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1100,
  backdropFilter: "blur(10px)",
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const HeaderInner = styled(Container)(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 64,
  position: "relative",
  padding: theme.spacing(0, 2),
  maxWidth: "920px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(4),
  },
}));

const NavItem = styled(Box)(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  cursor: "pointer",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.2s ease-in-out",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1, 2),
    fontSize: "1rem",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontWeight: "bold",
  },
}));

const InvitePopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: "blur(10px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    borderRadius: theme.shape.borderRadius * 2,
    width: 400,
    maxWidth: "90vw",
    maxHeight: "80vh",
    overflow: "hidden",
  },
}));

const StyledAvatar = styled(IconButton)(({ theme }) => ({
  padding: 0,
  width: 36,
  height: 36,
  borderRadius: "50%",
  border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  position: "relative",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    borderColor: alpha(theme.palette.primary.main, 0.4),
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: "blur(10px)",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

const navigationItems = [
  { path: "/dashboard", label: "Dashboard", icon: DashboardIcon },
  { path: "/groups", label: "Groups", icon: Group },
  { path: "/transactions", label: "Transactions", icon: HistoryIcon },
  {
    path: "/invites",
    label: "Invites",
    icon: MailIcon,
  },
  { path: "/settings", label: "Settings", icon: SettingsIcon },
];

const HeaderNav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inviteAnchorEl, setInviteAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, pendingInvites } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleInviteClick = (event) => {
    setInviteAnchorEl(event.currentTarget);
  };

  const handleInviteClose = () => {
    setInviteAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
      handleMenuClose();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleNavClick = (path) => {
    navigate(path);
  };

  return (
    <StyledHeader>
      <HeaderInner>
        <NavigationContainer>
          {navigationItems.map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.path;
            return (
              <NavItem
                key={item.path}
                active={isActive}
                onClick={() => handleNavClick(item.path)}
              >
                <Icon />
                {!isMobile && item.label}
              </NavItem>
            );
          })}
        </NavigationContainer>

        <Box
          sx={{
            position: "absolute",
            right: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {pendingInvites?.length > 0 && (
            <Tooltip title="Pending Invites">
              <IconButton
                onClick={handleInviteClick}
                sx={{
                  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                  },
                }}
              >
                <StyledBadge badgeContent={pendingInvites.length} color="error">
                  <MailIcon color="secondary" />
                </StyledBadge>
              </IconButton>
            </Tooltip>
          )}

          <StyledAvatar onClick={handleMenuOpen}>
            <Avatar
              src={user?.photoURL || "/api/placeholder/40/40"}
              alt={user?.displayName || "User"}
            />
          </StyledAvatar>
        </Box>

        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={handleSignOut}
            sx={{
              color: theme.palette.error.main,
              "&:hover": {
                backgroundColor: alpha(theme.palette.error.main, 0.1),
              },
            }}
          >
            Sign Out
          </MenuItem>
        </StyledMenu>

        <InvitePopover
          open={Boolean(inviteAnchorEl)}
          anchorEl={inviteAnchorEl}
          onClose={handleInviteClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Pending Invites
            </Typography>
            <Box sx={{ maxHeight: "60vh", overflow: "auto" }}>
              <InviteList onInviteHandled={handleInviteClose} />
            </Box>
          </Box>
        </InvitePopover>
      </HeaderInner>
    </StyledHeader>
  );
};

export default HeaderNav;
