import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  alpha,
  styled,
  Container,
  Skeleton,
  Avatar,
  Chip,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";
import { getUserTransactions } from "../backend-services/firebase";
import { useAuth } from "../contexts/AuthContext";
import { motion } from "framer-motion";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "800px",
  padding: theme.spacing(2),
  margin: "0 auto",
  paddingBottom: theme.spacing(8), // Space for bottom navigation
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    color: theme.palette.text.primary,
    backdropFilter: "blur(8px)",
    fontSize: 13,
    padding: "8px 12px",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& .participant-name": {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    "& .participant-email": {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  },
}));

const MonthSection = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.background.paper,
    0.8
  )} 0%, ${alpha(theme.palette.background.paper, 0.6)} 100%)`,
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  overflow: "hidden",
  [theme.breakpoints.up("sm")]: {
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(3),
  },
}));

const MonthHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.background.paper,
    0.5
  )} 0%, ${alpha(theme.palette.background.paper, 0.3)} 100%)`,
}));

const TransactionList = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const TransactionItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: "blur(8px)",
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  "&:last-child": {
    marginBottom: 0,
  },
  display: "flex",
  gap: theme.spacing(2),
  // Mobile-only changes
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const TransactionDetails = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
  // Keep desktop layout as is, only change for mobile
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const ParticipantAvatars = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    width: 24,
    height: 24,
    fontSize: "0.75rem",
    border: `2px solid ${theme.palette.background.paper}`,
    marginLeft: -8,
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
}));

const EmptyStateContainer = motion(
  styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 400,
    padding: theme.spacing(4),
    backdropFilter: "blur(10px)",
    position: "relative",
    overflow: "hidden",
  }))
);

const EmptyStateIcon = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: "50%",
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  "& svg": {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
}));

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";

  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num))
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!user?.uid) return;
      try {
        setLoading(true);
        setError(null); // Reset error state
        const userTransactions = await getUserTransactions(user.uid);

        // Group transactions by month
        const grouped = userTransactions.reduce((acc, transaction) => {
          const date = transaction.settledAt?.toDate() || new Date();
          const monthKey = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}`;

          if (!acc[monthKey]) {
            acc[monthKey] = [];
          }
          acc[monthKey].push(transaction);
          return acc;
        }, {});

        setTransactions(grouped);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [user]);

  const renderEmptyState = () => (
    <EmptyStateContainer>
      <EmptyStateIcon>
        <HistoryIcon />
      </EmptyStateIcon>
      <Typography variant="h5" color="primary.main" gutterBottom>
        No Transactions Found
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ maxWidth: 300, textAlign: "center" }}
      >
        Your transaction history will appear here
      </Typography>
    </EmptyStateContainer>
  );

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatMonthHeader = (monthKey) => {
    const [year, month] = monthKey.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long" });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(Math.abs(amount));
  };

  const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };

  if (loading) {
    return (
      <PageContainer>
        <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 4 }}>
          Transactions
        </Typography>
        {[1, 2, 3].map((index) => (
          <MonthSection key={index}>
            <MonthHeader>
              <Skeleton width={150} height={30} />
            </MonthHeader>
            <TransactionList>
              {[1, 2, 3].map((itemIndex) => (
                <TransactionItem key={itemIndex}>
                  <Box>
                    <Skeleton width={200} height={24} />
                    <Skeleton width={150} height={20} />
                  </Box>
                  <Skeleton width={100} height={24} />
                </TransactionItem>
              ))}
            </TransactionList>
          </MonthSection>
        ))}
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 4 }}>
        Transactions
      </Typography>

      {error && (
        <Box sx={{ mb: 3 }}>
          <Paper
            sx={{
              p: 2,
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
              color: "error.main",
              border: "1px solid",
              borderColor: (theme) => alpha(theme.palette.error.main, 0.2),
            }}
          >
            <Typography>{error}</Typography>
          </Paper>
        </Box>
      )}

      {Object.entries(transactions)
        .sort(([a], [b]) => b.localeCompare(a))
        .map(([monthKey, monthTransactions]) => (
          <MonthSection key={monthKey}>
            <MonthHeader>
              <Typography variant="h6" color="primary">
                {formatMonthHeader(monthKey)}
              </Typography>
            </MonthHeader>
            <TransactionList>
              {monthTransactions.map((transaction) => {
                const payer = transaction.billDetails?.participants.find(
                  (p) => p.userId === transaction.billDetails?.paidBy
                );
                const participants =
                  transaction.billDetails?.participants || [];
                const visibleParticipants = participants.slice(0, 5);
                const remainingCount = Math.max(0, participants.length - 5);
                const billFirstLetter = transaction.billDescription
                  .charAt(0)
                  .toUpperCase();
                const backgroundColor = stringToColor(
                  transaction.billDescription
                );

                return (
                  <TransactionItem key={transaction.id}>
                    <Avatar
                      sx={{
                        bgcolor: backgroundColor,
                        width: { xs: 32, sm: 40 }, // Preserve desktop size
                        height: { xs: 32, sm: 40 },
                        fontSize: { xs: "1rem", sm: "1.2rem" },
                        fontWeight: 600,
                      }}
                    >
                      {billFirstLetter}
                    </Avatar>

                    <TransactionDetails>
                      <Box
                        sx={{
                          flex: 1,
                          // Preserve desktop layout, adjust only for mobile
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          gap: { xs: 1, sm: 2 },
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            {transaction.billDescription}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              // Adjust layout for mobile
                              flexDirection: { xs: "column", sm: "row" },
                              alignItems: { xs: "flex-start", sm: "center" },
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {formatDate(transaction.settledAt)}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{ display: { xs: "none", sm: "block" } }}
                            >
                              •
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                // Keep desktop layout, adjust for mobile
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                Paid Full Amount:
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 0.5,
                                }}
                              >
                                <StyledTooltip
                                  title={
                                    <div>
                                      <div className="participant-name">
                                        {payer?.displayName}
                                      </div>
                                      <div className="participant-email">
                                        {payer?.email}
                                      </div>
                                    </div>
                                  }
                                >
                                  <Avatar
                                    src={
                                      payer?.photoURL ||
                                      "/api/placeholder/24/24"
                                    }
                                    alt={payer?.displayName}
                                    sx={{
                                      width: { xs: 20, sm: 24 },
                                      height: { xs: 20, sm: 24 },
                                      border: (theme) =>
                                        `2px solid ${theme.palette.success.main}`,
                                      backgroundColor: (theme) =>
                                        alpha(theme.palette.success.main, 0.1),
                                    }}
                                  />
                                </StyledTooltip>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "success.main",
                                    fontWeight: 500,
                                  }}
                                >
                                  {payer?.displayName || "Unknown"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: { xs: 1, sm: 2 },
                            // Preserve desktop layout
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <ParticipantAvatars>
                            {visibleParticipants.map((participant) => (
                              <StyledTooltip
                                key={participant.userId}
                                title={
                                  <div>
                                    <div className="participant-name">
                                      {participant.displayName}
                                    </div>
                                    <div className="participant-email">
                                      {participant.email}
                                    </div>
                                  </div>
                                }
                              >
                                <Avatar
                                  src={
                                    participant.photoURL ||
                                    "/api/placeholder/24/24"
                                  }
                                  alt={participant.displayName}
                                  sx={{
                                    width: { xs: 20, sm: 24 },
                                    height: { xs: 20, sm: 24 },
                                  }}
                                />
                              </StyledTooltip>
                            ))}
                            {remainingCount > 0 && (
                              <StyledTooltip
                                title={`${remainingCount} more participant${
                                  remainingCount > 1 ? "s" : ""
                                }`}
                              >
                                <Avatar
                                  sx={{
                                    width: { xs: 20, sm: 24 },
                                    height: { xs: 20, sm: 24 },
                                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                                  }}
                                >
                                  +{remainingCount}
                                </Avatar>
                              </StyledTooltip>
                            )}
                          </ParticipantAvatars>
                          <Tooltip title="Split Type">
                            <Typography
                              variant="caption"
                              sx={{
                                bgcolor: alpha(
                                  theme.palette.secondary.main,
                                  0.1
                                ),
                                color: "secondary.main",
                                px: 1,
                                py: 0.5,
                                borderRadius: 1,
                                fontSize: { xs: "0.7rem", sm: "0.75rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Split Type:{" "}
                              {transaction.billDetails?.splitType || "equal"}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          mt: { xs: 1, sm: 0 },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color={
                            transaction.type === "payment"
                              ? "success.main"
                              : "primary.main"
                          }
                          sx={{ fontWeight: 600 }}
                        >
                          {transaction.type === "payment" ? "+" : "-"}
                          {formatAmount(transaction?.amount?.original || 0)}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Total:{" "}
                          {formatAmount(
                            transaction.billDetails?.totalAmount || 0
                          )}
                        </Typography>
                      </Box>
                    </TransactionDetails>
                  </TransactionItem>
                );
              })}
            </TransactionList>
          </MonthSection>
        ))}

      {Object.keys(transactions).length === 0 && renderEmptyState()}
    </PageContainer>
  );
};

export default TransactionHistory;
