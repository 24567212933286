import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  styled,
  alpha,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import UserSearch from "./UserSearch";
import BillParticipantsTree from "./BillParticipantsTree";
import ItemSplitInput from "./ItemSplitInput";
import { useBillManagement } from "../hooks/useBillManagement";
import {
  updateBill,
  createBill,
  logBillActivity,
  logBillUpdated,
} from "../backend-services/firebase";
import { FormCard, FormField, StyledInput } from "./AddBill";

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  width: "100%",
  padding: "12px",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    color: theme.palette.common.black,
  },
}));

const BillForm = ({ initialData = null, mode = "edit", onSuccess }) => {
  const { user } = useAuth();
  const {
    billData,
    billError,
    participantShares,
    handleBillDataChange,
    handleParticipantChange,
    handleSplitValueChange,
    handleItemAdd,
    handleItemRemove,
    createSingleBill,
    handleSplitTypeChange,
    calculateTotalAmount,
    validateSplitValues,
    validationMessages,
    setBillError,
    updateBillData,
    setBillData,
    recalculateShares,
  } = useBillManagement();

  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    amount: "",
    paidBy: "",
    splitType: "equal",
  });

  useEffect(() => {
    if (mode === "edit" && initialData) {
      setFormValues({
        name: initialData.description,
        amount: initialData.amount.toString(),
        paidBy: initialData.paidBy,
        splitType: initialData.splitType,
      });

      updateBillData({
        name: initialData.description,
        description: initialData.description,
        amount: initialData.amount.toString(),
        splitType: initialData.splitType,
        participants: initialData.participants,
        paidBy: initialData.paidBy,
        shares: initialData.shares || {},
        items: initialData.items || [],
        groupId: initialData.groupId,
      });
    }
  }, [initialData, mode]);

  useEffect(() => {
    if (
      mode === "edit" &&
      formValues.amount &&
      billData.participants?.length > 0
    ) {
      const totalAmount = parseFloat(formValues.amount);
      if (!isNaN(totalAmount)) {
        const updatedShares = {};
        let perPersonShare;

        switch (billData.splitType) {
          case "equal":
            perPersonShare = totalAmount / billData.participants.length;
            billData.participants.forEach((participant) => {
              if (participant.userId === billData.paidBy) {
                updatedShares[participant.userId] = {
                  original: perPersonShare,
                  settlement: perPersonShare - totalAmount,
                };
              } else {
                updatedShares[participant.userId] = {
                  original: perPersonShare,
                  settlement: perPersonShare,
                };
              }
            });
            break;

          case "percentage":
            billData.participants.forEach((participant) => {
              const percentage =
                participantShares[participant.userId]?.percentage || 0;
              const share = (totalAmount * percentage) / 100;
              updatedShares[participant.userId] = {
                original: share,
                settlement:
                  participant.userId === billData.paidBy
                    ? share - totalAmount
                    : share,
              };
            });
            break;

          case "shares":
            const totalShares = billData.participants.reduce(
              (sum, p) => sum + (participantShares[p.userId]?.shares || 1),
              0
            );
            billData.participants.forEach((participant) => {
              const shares = participantShares[participant.userId]?.shares || 1;
              const share = (totalAmount * shares) / totalShares;
              updatedShares[participant.userId] = {
                original: share,
                settlement:
                  participant.userId === billData.paidBy
                    ? share - totalAmount
                    : share,
              };
            });
            break;
        }

        updateBillData((prev) => ({
          ...prev,
          shares: updatedShares,
        }));
      }
    }
  }, [formValues.amount, billData.paidBy, billData.splitType]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setBillError(null);

      if (mode === "edit") {
        const finalParticipants = billData.participants.map((participant) => {
          const share = billData.shares[participant.userId] || {
            original: 0,
            settlement: 0,
          };

          return {
            ...participant,
            share: {
              original: parseFloat(share.original || 0),
              settlement: parseFloat(share.settlement || 0),
            },
            splitValue: {
              percentage:
                participantShares[participant.userId]?.percentage || null,
              shares: participantShares[participant.userId]?.shares || null,
            },
          };
        });
        const updateData = {
          description: formValues.name,
          amount: parseFloat(formValues.amount),
          paidBy: formValues.paidBy,
          splitType: formValues.splitType,
          participants: finalParticipants,
          items: billData.items || [],
          shares: billData.shares,
          lastUpdated: new Date(),
          status: initialData.status,
          groupId: initialData.groupId,
          createdBy: initialData.createdBy,
          date: initialData.date,
        };

        const changes = {};
        if (updateData.description !== initialData.description)
          changes.description = true;
        if (updateData.amount !== initialData.amount) changes.amount = true;
        if (updateData.paidBy !== initialData.paidBy) changes.paidBy = true;
        if (updateData.splitType !== initialData.splitType)
          changes.splitType = true;
        if (
          JSON.stringify(updateData.participants) !==
          JSON.stringify(initialData.participants)
        )
          changes.participants = true;
        if (
          JSON.stringify(updateData.items) !== JSON.stringify(initialData.items)
        )
          changes.items = true;

        await updateBill(initialData.id, updateData);
        if (initialData.groupId) {
          await logBillUpdated(initialData.groupId, {
            bill: {
              id: initialData.id,
              ...updateData
            },
            initiator: {
              userId: user.uid,
              displayName: user.displayName || '',
              photoURL: user.photoURL || ''
            },
            changes
          });
        }
        setSuccessMessage("Bill updated successfully!");

        if (onSuccess) {
          onSuccess(updateData);
        }
      } else {
        const result = await createSingleBill(billData.groupId);
        if (result.success) {
          setSuccessMessage("Bill created successfully!");
          if (onSuccess) {
            onSuccess(result);
          }
        }
      }
    } catch (error) {
      console.error("Error submitting bill:", error);
      setBillError(error.message || "Failed to save bill");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      name: value,
    }));
    updateBillData((prev) => ({
      ...prev,
      name: value,
      description: value,
    }));
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      amount: value,
    }));

    const totalAmount = parseFloat(value);
    setBillData((prev) => ({
      ...prev,
      amount: totalAmount,
    }));
    if (!isNaN(totalAmount)) {
      const updatedShares = {};
      const perPersonShare = totalAmount / billData.participants.length;
      billData.participants.forEach((participant) => {
        if (participant.userId === billData.paidBy) {
          updatedShares[participant.userId] = {
            original: perPersonShare,
            settlement: perPersonShare - totalAmount,
          };
        } else {
          updatedShares[participant.userId] = {
            original: perPersonShare,
            settlement: perPersonShare,
          };
        }
      });

      setBillData((prev) => ({
        ...prev,
        shares: updatedShares,
      }));
    }

    recalculateShares(billData.splitType);
  };

  const handlePayerChangeLocal = (e) => {
    const value = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      paidBy: value,
    }));
    updateBillData((prev) => ({
      ...prev,
      paidBy: value,
    }));
    recalculateShares(billData.splitType);
  };

  const handleSplitTypeChangeLocal = (e) => {
    const value = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      splitType: value,
    }));
    handleSplitTypeChange(value);
  };

  const canEdit =
    mode === "edit" &&
    !initialData?.participants?.some((p) => p.settled) &&
    initialData?.status !== "completed";

  return (
    <FormCard>
      <Box sx={{ opacity: isSubmitting ? 0.3 : 1 }}>
        <FormField>
          <StyledInput
            fullWidth
            placeholder="Bill Name"
            value={formValues.name}
            onChange={handleNameChange}
            disabled={!canEdit}
            error={Boolean(validationMessages.name)}
            helperText={validationMessages.name}
          />
        </FormField>

        <FormField>
          <UserSearch
            multiple
            value={billData.participants || []}
            onChange={handleParticipantChange}
            excludeUsers={[{ userId: user?.uid }]}
            showRecent={false}
            placeholder="Search and add users..."
            disabled={true}
          />
        </FormField>

        <FormField>
          <FormControl fullWidth>
            <Select
              value={formValues.paidBy}
              onChange={handlePayerChangeLocal}
              disabled={!canEdit}
              displayEmpty
            >
              {billData.participants.map((p) => (
                <MenuItem key={p.userId} value={p.userId}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Avatar
                      src={p.photoURL || "/api/placeholder/32/32"}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography>{p.displayName || p.email}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormField>

        <FormField>
          <FormControl fullWidth>
            <Select
              value={formValues.splitType}
              onChange={handleSplitTypeChangeLocal}
              disabled={!canEdit}
            >
              <MenuItem value="equal">Split Equally</MenuItem>
              <MenuItem value="percentage">Split by Percentage</MenuItem>
              <MenuItem value="shares">Split by Shares</MenuItem>
              <MenuItem value="items">Split by Items</MenuItem>
            </Select>
          </FormControl>
        </FormField>

        {formValues.splitType === "items" ? (
          <ItemSplitInput
            billParticipants={billData.participants}
            items={billData.items || []}
            onAddItem={handleItemAdd}
            onRemoveItem={handleItemRemove}
            disabled={!canEdit}
          />
        ) : (
          <FormField>
            <StyledInput
              fullWidth
              type="number"
              placeholder="Amount"
              value={formValues.amount}
              onChange={handleAmountChange}
              disabled={!canEdit}
            />
          </FormField>
        )}

        {validateSplitValues() && (
          <FormField>
            <BillParticipantsTree
              paidBy={billData.participants?.find(
                (p) => p.userId === billData.paidBy
              )}
              participants={billData.participants || []}
              amount={calculateTotalAmount()}
              splitType={billData.splitType}
              shares={billData.shares}
              items={billData.items}
            />
          </FormField>
        )}

        <SubmitButton
          onClick={handleSubmit}
          disabled={!validateSplitValues() || isSubmitting || !canEdit}
        >
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : mode === "edit" ? (
            "Update Bill"
          ) : (
            "Create Bill"
          )}
        </SubmitButton>
      </Box>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(billError)}
        autoHideDuration={6000}
        onClose={() => setBillError("")}
      >
        <Alert severity="error">{billError}</Alert>
      </Snackbar>
    </FormCard>
  );
};

export default BillForm;
