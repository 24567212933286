// src/components/invite/InviteModal.js

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  alpha,
  styled,
  IconButton,
  Chip,
  CircularProgress,
  Slide,
  Alert,
} from "@mui/material";
import {
  Close as CloseIcon,
  Send as SendIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import {
  createInvite,
  updateInviteEmailStatus,
  updateInviteStatus,
} from "../../backend-services/firebase";
import { sendInviteEmail } from "../../backend-services/emailService";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      position: "fixed",
      bottom: 0,
      borderRadius: `${theme.shape.borderRadius * 2}px ${
        theme.shape.borderRadius * 2
      }px 0 0`,
      maxHeight: "90vh",
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    },
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(145deg, ${alpha(
        "#1A1D24",
        0.98
      )} 0%, ${alpha("#141519", 0.98)} 100%)`,
      backdropFilter: "blur(10px)",
      borderRadius: theme.shape.borderRadius * 2,
      border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
      maxWidth: "500px",
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiBackdrop-root": {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      backdropFilter: "blur(8px)",
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
  },
}));

const EmailChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  color: theme.palette.primary.main,
  "& .MuiChip-deleteIcon": {
    color: alpha(theme.palette.primary.main, 0.7),
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  "&.MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    }
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  "& .MuiTypography-root": {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  }
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  gap: theme.spacing(1)
}));

const InviteModal = ({
  open,
  onClose,
  type = "personal",
  groupId = null,
  groupName = null,
}) => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [emails, setEmails] = useState([]);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddEmail = () => {
    if (email && validateEmail(email)) {
      if (!emails.includes(email.toLowerCase())) {
        setEmails([...emails, email.toLowerCase()]);
        setEmail("");
        setError("");
      } else {
        setError("Email already added");
      }
    } else {
      setError("Please enter a valid email");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && email) {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter((e) => e !== emailToRemove));
  };

  const handleClose = () => {
    setEmail("");
    setMessage("");
    setEmails([]);
    setError("");
    setSuccessMessage("");
    onClose();
  };

  const handleSubmit = async () => {
    if (emails.length === 0) {
      setError("Please add at least one email");
      return;
    }

    setLoading(true);
    setError("");

    const results = [];

    for (const emailAddress of emails) {
      try {
        const inviteData = {
          type,
          fromUserId: user.uid,
          toEmail: emailAddress.toLowerCase(),
          message,
          groupId,
          groupName,
        };

        const invite = await createInvite(inviteData);
        try {
          const emailResult = await sendInviteEmail({
            id: invite.id,
            type,
            to: { email: emailAddress },
            from: {
              userId: user.uid,
              displayName: user.displayName,
              email: user.email,
            },
            message,
            metadata: { groupName },
          });

          await updateInviteEmailStatus(invite.id, {
            emailSent: true,
            emailId: emailResult.messageId,
          });

          results.push({ email: emailAddress, success: true });
        } catch (emailError) {
          await updateInviteEmailStatus(invite.id, {
            emailSent: false,
            error: emailError.message,
          });
          results.push({
            email: emailAddress,
            success: false,
            error: emailError.message,
          });
        }
      } catch (error) {
        results.push({
          email: emailAddress,
          success: false,
          error: error.message,
        });
      }
    }
    const failures = results.filter((r) => !r.success);
    if (failures.length > 0) {
      setError(
        `Failed to send invites to: ${failures.map((f) => f.email).join(", ")}`
      );
    } else {
      setSuccessMessage("All invites sent successfully!");
      setTimeout(handleClose, 500);
    }

    setLoading(false);
  };

  return (
    <StyledDialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    TransitionComponent={Slide}
    TransitionProps={{ direction: "up" }}
  >
    <StyledDialogTitle>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h6">
          {type === "group" ? `Invite to ${groupName}` : "Invite to WiseSplit"}
        </Typography>
        <IconButton 
          onClick={onClose} 
          size="small"
          sx={{ color: 'text.secondary' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledDialogTitle>

    <StyledDialogContent>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <StyledTextField
          fullWidth
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Enter email address"
          InputProps={{
            endAdornment: (
              <ActionButton
                onClick={handleAddEmail}
                disabled={!email || loading}
                startIcon={<AddIcon />}
                variant="text"
                size="small"
              >
                Add
              </ActionButton>
            ),
          }}
        />
      </Box>

      {emails.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {emails.map((email) => (
              <EmailChip
                key={email}
                label={email}
                onDelete={loading ? undefined : () => handleRemoveEmail(email)}
              />
            ))}
          </Box>
        </Box>
      )}
    </StyledDialogContent>

    <StyledDialogActions>
      <ActionButton 
        onClick={onClose} 
        disabled={loading} 
        variant="text"
        color="inherit"
      >
        Cancel
      </ActionButton>
      <ActionButton
        variant="contained"
        onClick={handleSubmit}
        disabled={emails.length === 0 || loading}
        startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
      >
        {loading ? "Sending..." : "Send Invites"}
      </ActionButton>
    </StyledDialogActions>
  </StyledDialog>
  );
};

export default InviteModal;
