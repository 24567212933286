const RESEND_API_KEY = "re_iT3DBig2_6McWQiehcLGKdZzW4gmhJfsA";

const PROXY_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/wise-split-be/us-central1/sendEmail"
    : "https://us-central1-wise-split-be.cloudfunctions.net/sendEmail";

export const sendInviteEmail = async (invite) => {
  try {
    console.log("Attempting to send email to:", invite.to.email);
    console.log("Using PROXY_URL:", PROXY_URL);

    const response = await fetch(PROXY_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        to: invite.to.email,
        subject: `You've been invited to ${
          invite.type === "group"
            ? `join ${invite.metadata?.groupName} on `
            : ""
        }WiseSplit`,
        from: invite.from,
        message: invite.message,
        metadata: invite.metadata,
        id: invite.id,
      }),
    });

    console.log("Response status:", response.status);

    if (!response.ok) {
      const errorData = await response
        .json()
        .catch((e) => ({ message: "No error details available" }));
      console.error("Error details:", errorData);
      throw new Error(errorData.message || "Failed to send email");
    }

    const data = await response.json();
    return {
      success: true,
      messageId: data.id,
    };
  } catch (error) {
    console.error("Detailed error:", {
      name: error.name,
      message: error.message,
      stack: error.stack,
    });
    throw error;
  }
};
