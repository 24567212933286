import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  alpha,
  styled,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Mail as MailIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import InviteModal from "./InviteModal";
import { useAuth } from "../../contexts/AuthContext";
import InviteList from "./InviteList";

const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "800px",
  padding: theme.spacing(3),
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
}));

const InviteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.8),
  },
}));

const ContentCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  padding: theme.spacing(3),
  minHeight: "400px",
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  height: "100%",
  minHeight: "300px",
  textAlign: "center",
  "& .MuiSvgIcon-root": {
    fontSize: 48,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    opacity: 0.5,
  },
}));

const InvitesManagement = () => {
  const { pendingInvites, loadingInvites } = useAuth();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  return (
    <PageContainer>
      <HeaderSection>
        <Typography variant="h4" color="primary" fontWeight="bold">
          Invites
        </Typography>
        <InviteButton
          startIcon={<PersonAddIcon />}
          onClick={() => setIsInviteModalOpen(true)}
        >
          Invite Users
        </InviteButton>
      </HeaderSection>

      <InviteModal
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        type="personal"
      />

      <ContentCard>
        {loadingInvites ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
            <CircularProgress />
          </Box>
        ) : pendingInvites?.length > 0 ? (
          <InviteList invites={pendingInvites} />
        ) : (
          <EmptyState>
            <MailIcon />
            <Typography variant="h6" gutterBottom>
              No Pending Invites
            </Typography>
            <Typography variant="body2" color="text.secondary">
              When someone invites you to a group or to split a bill, you'll see
              their invites here.
            </Typography>
          </EmptyState>
        )}
      </ContentCard>
    </PageContainer>
  );
};

export default InvitesManagement;
